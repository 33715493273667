const dateTimeFormats = {
    short: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    },
    long: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false
    }
}
// A note about vue3 i18n translation texts:
// '{', '}', @ $ | are special characters in i18n now, so they need to be escaped by literal interpolation like in "{0} {'|'} {1} / {2} möglichen Zeichen"
// For further details see https://vue-i18n.intlify.dev/guide/essentials/syntax#special-characters
const messages = {
    generals: {
        wwaMaintenanceMessage: '<div style="font-family: Source Sans Pro Bold, sans-serif; color: var(--color-warning); margin: 16px 0;">Wartungsarbeiten werden durchgeführt! 🪓 🧨</div>Liebe Nutzer*innen, durch Arbeiten an WoodWing Assets ist auch CorFlow betroffen und deshalb nur eingeschränkt funktionstüchtig. Bitte benutzt CorFlow bis auf Weiteres nicht - wir melden, sobald alles wieder reibungslos funktioniert. (Für die Nutzer*innen, die ggf. gestern und heute Fehlermeldungen erhalten haben, die betroffenen Aufgaben werden nach Wiederverfügbarkeit von WoodWing Assets erneut von CorFlow ausgeführt.)',
        sapMaintenanceMessage: '<div style="font-family: Source Sans Pro Bold, sans-serif; color: var(--color-warning); margin: 16px 0;">Wartungsarbeiten an SAP werden durchgeführt! 🧪 🔥 💥</div>Liebe Nutzer*innen, aktuell läuft die Umstellung von SAP auf SRE. Da die Kommunikation mit SAP und dessen Nutzung innerhalb dieser Phase nicht möglich ist, sind einige wenige Funktionen in CorFlow eingeschränkt. Dabei handelt es sich um die Anlage neuer Produkte, sowie die Anpassung und Rückmeldung von Vorgängen in den SAP-Zeitplänen. Wir bitten um Verständnis und geben Entwarnung, sobald die Umstellung vollständig durchgeführt wurde.<br /><br />Viele Grüße - Das CorFlow Team',
        sfMaintenanceMessage: '<div style="font-family: Source Sans Pro Bold, sans-serif; color: var(--color-warning); margin: 16px 0;">Wartungsarbeiten an SiteFusion werden durchgeführt! 🛠️ 🔬 🩹</div>Liebe Nutzer*innen, von 16 bis voraussichtlich 17 Uhr werden Wartungsarbeiten an SiteFusion durchgeführt. Da CorFlow einige Schnittstellen von SiteFusion verwendet, ist der XML-Workflow zum Teil eingeschränkt, speziell die Abfrage der SiteFusion-Id und der Paketimport nach SiteFusion. Wir empfehlen in der genannten Zeitspanne nicht am XML-Workflow weiterzuarbeiten. Wir bitten um Verständnis und geben Entwarnung, sobald die Wartungsarbeiten abgeschlossen wurden.<br /><br />Viele Grüße - Das CorFlow Team',
        infoNotAvailable: 'Information nicht vorhanden',
        valueUnknown: '\'Unbekannt\'',
        valueEmpty: '\'Kein Wert\'',
        unknownError: 'Unbekannter Fehler - Dieser Fehler tritt auf, wenn der Service aktuell nicht zur Verfügung steht, beispielsweise während eines Deployments, oder ein Fehler auftritt, der uns so bislang nicht bekannt ist. Wende dich für weitere Hilfe an den Publishing-Support unter ' +
            '<a href="' + "mailto:publishing-support{'@'}cornelsen.de" + '">' + "publishing-support{'@'}cornelsen.de" + '</a>.',
        goToProduct: 'Ins Produkt springen',
        sapProduct: 'sapProduct',
        save: 'Speichern',
        cancel: 'Abbrechen',
        confirmDelete: 'Wirklich löschen?',
        systemInformation: 'SYSTEM',
        copyToClipboard: 'In Zwischenablage kopieren',
        copiedToClipboard: 'In Zwischenablage kopiert',
        planned: 'SOLL',
        actual: 'IST',
        contentTypes: {
            contentWithCorrection: 'Bestandteil mit Korrektur (z.B. Kapitel)',
            contentWithoutCorrection: 'Bestandteil ohne Korrektur (z.B. Cover)'
        },
        stopTaskTermination: {
            info: 'Abschluss der Aufgabe abbrechen?',
            buttonText: 'Ja, abbrechen'
        },
        roleLabels: {
            ZP01: 'Redaktion',
            ZP03: 'Außenredaktion',
            ZP04: 'Vorautor*in',
            ZP05: 'Herausgeber*in',
            ZP06: 'Beratung',
            ZP07: 'Redaktionsleitung',
            ZP08: 'Marketingmanagement',
            ZP10: 'Produktmanagement',
            ZP11: 'Satzdienstleistung',
            ZPXX: 'XML-Dienstleistung',
            EINR: 'Einreichung',
            ZP12: 'Contentmanagement',
            ZP99: 'Projektkoordination',
            unknown: 'Unbekannte Rolle'
        },
        productStateValues: {
            CREATED: 'Angelegt',
            IN_DEVELOPMENT: 'In Entstehung',
            FINALIZED: 'Finalisiert',
            IMPRIMATUR_FINISHED: 'Imprimiert',
            ALL_FINISHED: 'Abgeschlossen',
            FINISHED_MANUALLY: 'Manuell beendet',
            PRODUCTION_INVALID: 'Produktion wurde irrtümlich angelegt',
            PRODUCTION_CANCELLED: 'Produktion wurde abgebrochen (TABG)',
            PRODUCTION_FINISHED: 'Produktion wurde außerhalb von CorFlow erfolgreich abgeschlossen',
            PRODUCTION_FINISHED_XML_CANCELLED: 'Produktion wurde abgeschlossen, aber XML-Erstellung abgebrochen',
            UNKNOWN: '@:generals.infoNotAvailable'
        },
        booleanValues: {
            true: 'Ja',
            false: 'Nein'
        },
        materialTypeValues: {
            ZSTP: 'ZSTP - Standardprodukt',
            ZSTN: 'ZSTN - Standardprodukt, nicht stofflich',
            ZUFE: 'ZUFE - Unfertiges Erzeugnis',
            ZUFN: 'ZUFN - Unfertiges Erzeugnis, nicht stofflich',
            ZZSA: 'ZZSA - Zeitschrift',
            ZMPR: 'ZMPR - Reihenausgabe',
            ZMPF: 'ZMPF - Reihe',
            ZWMI: 'ZWMI - Werbemittel, stofflich',
            ZWMN: 'ZWMN - Werbemittel, nicht stofflich',
            ZSON: 'ZSON - Sonstiges Produkt',
            UNKNOWN: 'Unbekannte Materialart'
        },
        poolProductionValues: {
            no: 'Nein',
            '50pct': 'Ja (50%)',
            '80pct': 'Ja (80%)',
            UNKNOWN: '–'
        },
        errorCodes: {
            CF_NotFound: 'Zu deiner Suche oder Eingabe konnte nichts gefunden werden. Möglicherweise greifst du auf etwas zu, das nicht (mehr) vorhanden ist. Überprüfe ggfs. deine Eingabe und versuche es erneut. Wende dich für weitere Hilfe an den Publishing-Support unter ' +
            '<a href="' + "mailto:publishing-support{'@'}cornelsen.de" + '">' + "publishing-support{'@'}cornelsen.de" + '</a>.',
            CF_Forbidden: 'Zur Ausführung dieser Aktion bist du leider nicht berechtigt',
            CF_Timeout: 'Die Bearbeitung deiner Anfrage musste abgebrochen werden, da diese zu lange dauerte',
            CF_InputMustBeNumber: 'Die Eingabe darf nur aus Ziffern bestehen (0-9)',
            CF_ContentPlanningNotAllowed: 'Prozess- oder rechtebedingt ist die Anlage von Produktbestandteilen nicht möglich',
            CF_ContentCreationAlreadyStarted: 'Der Entstehungsprozess für diesen Produktbestandteil wurde bereits gestartet',
            CF_ContentNameEmpty: 'Die Bezeichnung des Produktbestandteils darf nicht leer sein',
            CF_ContentTypeEmpty: 'Der Typ des Produktbestandteils darf nicht leer sein',
            CF_ContentTypeMaximumViolated: 'Die maximale Anzahl von Produktbestandteilen dieses Typs wurde überschritten',
            CF_TaskAlreadyClaimed: 'Die Aufgabe wurde inzwischen von einem anderen Nutzer reserviert',
            CF_TaskDataMissing: 'Die bereitgestellten Daten sind nicht vollständig',
            CF_Task_GatewayDecisionMissing: 'Es muss noch eine Entscheidung getroffen werden, um die Aufgabe abzuschließen',
            CF_Task_FormDataInvalid: 'Ein oder mehrere Pflichtfelder des Formulars wurden nicht befüllt - Bitte prüfe die einzelnen Seiten des Formulars auf leere Felder, die mit einem \'*\' markiert sind',
            CF_InvalidBpmnFileExtension: 'Bei der hochgeladenen Datei handelt es sich nicht um eine BPMN-Datei',
            CF_InvalidXMLContent: 'Die hochgeladene Datei hat keine valide XML-Struktur',
            CF_MissingProcessDefinition: 'Die hochgeladene Datei muss aus mindestens einer Prozessdefinition bestehen',
            CF_SearchParameterAtLeastOne: 'Es muss mindestens ein Suchkriterium angegeben werden',
            CF_SearchTextMustNotBeEmpty: 'Es muss ein Suchtext gesetzt sein',
            CF_ProductIdMustNotBeEmpty: 'Es muss eine Produkt ID gesetzt sein',
            CF_SiteFusionProductNotFound: 'Es existiert kein Produkt mit der angegebenen ID in SiteFusion',
            CF_UnsupportedProductionLine: 'Die Produktionsstrecke des Produkts wird in CorFlow (noch) nicht unterstützt.',
            CF_UnsupportedReprints: 'CorFlow unterstützt (aktuell) keine Produkte mit Nachdrucken.',
            CF_UnsupportedEditionStatus: 'CorFlow unterstützt (aktuell) nicht den angezeigten Nachdruck-Status.',
            CF_MissingProcessStep: 'Die in SAP angelegten Vorgänge scheinen fehlerhaft zu sein! Für dieses Produkt wird der Vorgang \'Lizenzrechtliche Imprimatur\' vorausgesetzt, welcher jedoch nicht vorhanden ist.',
            CF_UnsupportedMaterialType: 'Die Materialart dieses Produkts ist entweder in SAP nicht gesetzt oder wird von CorFlow (aktuell) nicht unterstützt.',
            CF_UnsupportedMaterialTypeAndMissingProcessStep: 'Die Materialart dieses Produkts wird von CorFlow nicht unterstützt und es fehlen SAP-Vorgänge, die für dieses Produkt vorausgesetzt sind.',
            CF_WwaUnavailable: 'WoodWing Assets ist aktuell nicht verfügbar.',
            CF_WwaProductPathNotFound: 'Der Titelordner in WoodWing Assets ist nicht vorhanden. Bitte prüfe, ob das Produkt in der PU-Buch angelegt wurde.',
            CF_InvalidWwaLink: 'Es wurde kein gültiger Woodwing Assets-Link hinterlegt.',
            CF_NoValidWwaLinkToAPdf: '@:generals.errorCodes.CF_InvalidWwaLink', // DocumentReference does not exist
            CF_CouldNotExtractAssetId: '@:generals.errorCodes.CF_InvalidWwaLink',
            CF_CouldNotExtractAuthKey: '@:generals.errorCodes.CF_InvalidWwaLink',
            CF_NoSuchAsset: 'Die referenzierte Datei existiert nicht.',
            CF_SearchHitWithoutAssetId: '@:generals.errorCodes.CF_NoSuchAsset',
            CF_NoPdf: 'Datei ist kein PDF und wird für die Vorschau ignoriert.',
            CF_NoProductContentPdf: 'Zu diesem Produktbestandteil wurde kein PDF gefunden.',
            CF_NoSuchDocumentReference: '@:generals.errorCodes.CF_NoSuchAsset',
            CF_NoResults: 'Der Woodwing Assets-Link konnte nicht gefunden werden. Der Link ist gegebenenfalls abgelaufen, oder zeigt auf einen leeren Ordner.',
            CF_AssetInTrashFolder: 'Der Woodwing Assets-Link zeigt auf eine Datei im Papierkorb.',
            CF_ExpiredOrUnknownAuthKey: 'Der Woodwing Assets-Link ist nicht (mehr) zugreifbar: \'{0}\'.',
            CF_ExceptionAccessingWwa: 'Beim Zugriff auf Woodwing Assets trat ein Fehler auf (Operation: \'{0}\' Argument: \'{1}\').',
            CF_FileLimitExceeded: 'Der Upload konnte nicht abgeschlossen werden, da das Dateilimit überschritten wurde.',
            CF_FormNotFound: 'Für dieses Produkt konnte kein zugehöriges Formular gefunden werden.'
        }
    },
    notification: {
        defaultMessage: 'Aktuell gibt es keine Meldungen',
        headlines: {
            info: 'Kurze Info',
            success: 'Sehr schön!',
            warning: 'Achtung!',
            error: 'Ups, da ging etwas schief!'
        }
    },
    contentHeader: {
        goBackTitleDefault: 'Zurück'
    },
    button: {
        defaultText: 'Button'
    },
    inlineConfirmButton: {
        defaultConfirmText: 'Bist du sicher?',
        confirm: 'Ja, forfahren',
        cancel: 'Nein, abbrechen'
    },
    textInput: {
        defaultPlaceholder: 'Text eingeben',
        maxLengthCounter: '{0} / {1} möglichen Zeichen',
        patternDoesNotMatch: 'Die Eingabe ist unzulässig'
    },
    emailInput: {
        defaultPlaceholder: 'E-Mail-Adresse eingeben',
        noValidEmail: 'Die Eingabe hat kein gültiges E-Mail-Format'
    },
    timeCodeInput: {
        noValidTimeCode: 'Die Eingabe ist keine gültige Zeitangabe'
    },
    readOnlyInput: {
        defaultPlaceholder: 'Kein Wert'
    },
    numberInput: {
        defaultPlaceholder: 'Anzahl hier eingeben',
        maximumViolated: 'Der zulässige Maximalwert für dieses Feld wurde überschritten - Der Maximalwert beträgt \'{0}\'',
        minimumViolated: 'Der zulässige Minimalwert für dieses Feld wurde unterschritten - Der Minimalwert beträgt \'{0}\'',
        enableEstimate: 'Als Schätzung festlegen',
        disableEstimate: 'Als Fixwert festlegen',
        maximumReached: 'Das Maximum von {0} Ziffern ist erreicht!'
    },
    areaInput: {
        defaultPlaceholder: 'Text eingeben (Shift + Enter für Umbruch)',
        maxLengthCounter: '{0} / {1} möglichen Zeichen',
        patternDoesNotMatch: '@:textInput.patternDoesNotMatch'
    },
    dateInput: {
        defaultPlaceholder: 'Datum auswählen'
    },
    checkList: {
        customOptionLabel: 'Sonstiges'
    },
    dropInput: {
        defaultPlaceholder: 'Datei hier ablegen',
        uploading: 'Upload wird durchgeführt',
        wrongFileType: 'Die Datei \'{0}\' kann nicht hochgeladen werden, da sie nicht den erlaubten Dateitypen entspricht - Erlaubte Dateitypen sind: {1}',
        fileLimitExceeded: 'Das Upload-Limit wird überschritten. Es können nur noch maximal {0} Dateien hochgeladen werden'
    },
    smartSelect: {
        defaultPlaceholder: 'Wert auswählen'
    },
    entryList: {
        defaultLabel: 'Unbekannter Listeneintrag',
        defaultText: 'Keine Einträge vorhanden'
    },
    sortableList: {
        headline: 'Sortiere die Einträge per einfachem Drag & Drop',
        resetList: 'Liste zurücksetzen',
        submitList: 'Sortierung anwenden',
        removeEntry: 'Eintrag entfernen'
    },
    dynamicInput: {
        inputTypeLabels: {
            text: 'Text hinterlegen',
            link: 'Link hinterlegen',
            date: 'Datum hinterlegen',
            email: 'E-Mail-Adresse hinterlegen',
            number: 'Nummer/Zahl hinterlegen',
            upload: 'Datei hochladen'
        },
        placeholder: 'Was möchtest du hinterlegen?',
        backToSelection: 'Zurück zur Auswahl'
    },
    smartForm: {
        defaultHeadline: 'Formular',
        tableOfContents: 'Inhaltsverzeichnis',
        repeatFormDefault: 'Daten wiederverwenden',
        addPage: '{0} hinzufügen',
        copyPage: '{0} kopieren und am Ende einfügen',
        deletePage: '{0} löschen',
        next: 'Weiter',
        back: 'Zurück',
        submit: 'Senden',
        requiredFieldsInfo: 'Mit einem * gekennzeichnete Felder sind Pflichtfelder und müssen belegt sein',
        incompleteMessage: {
            standard: 'Mindestens ein Pflichtfeld wurde nicht ausgefüllt oder ist nicht valide. Die betroffenen Felder sind rot markiert.',
            short: 'Mindestens ein Pflichtfeld ist leer oder inkorrekt'
        },
        apiError: 'Beim Abrufen weiterführender Informationen ist folgender Fehler aufgetreten:',
        deleteEntryError: 'Beim löschen des Eintrags ist folgender Fehler aufgetreten:',
        startSearch: 'Suchen',
        searchError: 'Beim Durchführen der Suche ist folgender Fehler aufgetreten:',
        emptySearchResult: {
            standard: 'Das Suchergebnis deiner Anfrage ist leider leer',
            short: 'Suchergebnis der Anfrage ist leer'
        },
        resetData: 'Ergebnisfelder zurücksetzen',
        collapseSearchResults: 'Suchergebnisse einklappen',
        expandSearchResults: 'Suchergebnisse ausklappen',
        tableFilter: 'Ergebnisliste filtern',
        pickEntry: 'Wähle den passenden Eintrag',
        selectData: 'Diese Daten verwenden',
        noSearchDefined: 'Technischer Fehler: Die Suche wurde nicht korrekt konfiguriert und kann nicht durchgeführt werden. Wende dich für weitere Hilfe an den Publishing-Support unter ' +
            '<a href="' + "mailto:publishing-support{'@'}cornelsen.de" + '">' + "publishing-support{'@'}cornelsen.de" + '</a>.',
        longRequestInfo: 'Die Anfrage kann etwas Zeit in Anspruch nehmen. Wenn du nicht warten möchtest, kannst du diese Ansicht schließen und zu einem späteren Zeitpunkt nachschauen, ob die Anfrage abgeschlossen wurde.',
        continueRequestInfo: 'Die letzte Anfrage wird noch bearbeitet. Wenn du nicht warten möchtest, kannst du diese Ansicht schließen und zu einem späteren Zeitpunkt zurückkehren.'
    },
    timeSpanInput: {
        missingValue: 'Anfang oder Ende der Zeitspanne wurde nicht gesetzt.',
        negativeTimeSpan: 'Das Ende der Zeitspanne muss nach dem Start liegen.',
        calculatedTimeSpan: 'Die Zeitspanne beträgt {0} Stunden.'
    },
    dualTextInput: {
        defaultPlaceholder: 'Text eingeben',
        missingValue: 'Der zweite Wert muss gesetzt sein.',
        maxLengthCounter: "{0} {'|'} {1} / {2} möglichen Zeichen"
    },
    modalContainer: {
        defaultHeaderTitle: 'Modale Ansicht'
    },
    pageOverlay: {
        defaultExpandPlaceholder: 'Ansicht ausklappen',
        defaultCollapsePlaceholder: 'Ansicht einklappen'
    },
    loadingScreen: {
        defaultText: 'Wir bearbeiten deine Anfrage im Hintergrund. Das kann etwas Zeit in Anspruch nehmen. Bitte habe einen Moment Geduld.',
        cancelText: 'Ansicht schließen'
    },
    table: {
        addUserFilterError: 'Beim Hinzufügen des Filterwerts \'{0}\' zu deinem Nutzerprofil ist folgender Fehler aufgetreten:',
        addUserFiltersError: 'Beim Hinzufügen der Filterwerte <br />{0}<br /> zu deinem Nutzerprofil ist folgender Fehler aufgetreten:',
        addUserFilterSuccess: {
            standard: 'Der Filterwert \'{0}\' wurde erfolgreich an deinem Nutzerprofil gespeichert',
            short: '\'{0}\' hinzugefügt'
        },
        addUserFiltersSuccess: {
            standard: 'Die Filterwerte <br />{0}<br /> wurden erfolgreich an deinem Nutzerprofil gespeichert',
            short: '{0} Filterwerte hinzugefügt'
        },
        removeUserFilterError: 'Beim Entfernen des Filterwerts \'{0}\' aus deinem Nutzerprofil ist folgender Fehler aufgetreten:',
        removeUserFilterSuccess: {
            standard: 'Der Filterwert \'{0}\' wurde erfolgreich aus deinem Nutzerprofil entfernt',
            short: '\'{0}\' entfernt'
        },
        changeMetadataSetError: 'Beim Speichern des Metadaten-Sets \'{0}\' in dein Nutzerprofil ist folgender Fehler aufgetreten:',
        changeSortingError: 'Beim Speichern der Sortierung in dein Nutzerprofil ist folgender Fehler aufgetreten:',
        exportVisibleRows: 'Cornelsen Schulbuchverlag (😜) - Sichtbare Tabellenzeilen als Excel exportieren',
        openFilterButtonText: 'Erweiterten Filter öffnen',
        closeFilterButtonText: 'Erweiterten Filter schließen',
        customFilterHelpText: '<div class="c_table-filter-hint-headline">- Wie funktioniert der erweiterte Filter? -</div>\n' +
            '<div class="c_table-filter-hint-paragraph">\n' +
            'Den erweiterten Filter kannst du nach belieben konfigurieren und er bleibt dir über deine Session hinweg erhalten!\n' +
            '</div>\n' +
            '<div class="c_table-filter-hint-paragraph">\n' +
            'Wähle einfach den gewünschten Wert aus der vorgegebenen Liste aus oder trage einen beliebigen Wert ein.\n' +
            'Bestätige anschließend deine Auswahl mit dem Häkchen oder der Enter-Taste, um deinen Filter zu speichern.\n' +
            '</div>\n' +
            '<div class="c_table-filter-hint-paragraph m--addition">\n' +
            '<span class="c_table-filter-hint-text-highlighted">Hinweis</span>: Wundere dich nicht darüber, dass dein Wert immer kleingeschrieben abgespeichert wird. Der Filter überprüft keine Groß- und Kleinschreibung.\n' +
            'Gleichermaßen stellen wir so sicher, dass du nicht aus Versehen einen Wert mehrfach in unterschiedlichen Schreibweisen abspeicherst.\n' +
            '</div>\n' +
            '<div class="c_table-filter-hint-paragraph">\n' +
            'Um einen Filterwert zu löschen, klicke diesen an und entferne ihn über das nun angezeigte \'x\'-Symbol.\n' +
            '</div>\n' +
            '<div class="c_table-filter-hint-paragraph">\n' +
            'Beachte, wie der Zähler im Button zum Öffnen und Schließen des Filters sich aktualisiert, wenn du einen Wert hinzufügst oder entfernst.\n' +
            'So weißt du zu jeder Zeit, ob Elemente auf Grund eines aktiven Filters ausgeblendet sein könnten.\n' +
            '</div>\n' +
            '<div class="c_table-filter-hint-headline">- Wie ergänzen sich Filterwerte? -</div>\n' +
            '<div class="c_table-filter-hint-paragraph">\n' +
            'Filterwerte der selben Spalte bzw. Kategorie sind so verknüpft, dass mindestens ein Wert zutreffen muss.\n' +
            'Filtere ich zum Beispiel eine Spalte nach den Werten \'844\' und \'925\', dann muss der Wert in der Zelle \'844\' UND/ODER \'925\' enthalten, damit die Zeile angezeigt wird.\n' +
            '</div>\n' +
            '<div class="c_table-filter-hint-paragraph">\n' +
            'Filterwerte unterschiedlicher Spalten bzw. Kategorien sind so miteinander verknüpft, dass pro Spalte mindestens ein gesetzter Filter zutreffen muss.\n' +
            'Filtere ich also, zusätzlich zum obigen Beispiel, eine andere Spalte nach \'Meier\', dann muss die obige Bedingung erfüllt sein UND der Wert in der Zelle \'Meier\' enthalten.\n' +
            'Sind beide Bedingungen erfüllt wird die Zeile angezeigt.\n' +
            '</div>\n' +
            '<div class="c_table-filter-hint-headline">- Gibt es erweiterte Syntax über Markups? -</div>\n' +
            '<div class="c_table-filter-hint-paragraph">\n' +
            'Es gibt Präfixe die die Filterlogik anpassen:\n' +
            '</div>\n' +
            '<div class="c_table-filter-hint-paragraph">\n' +
            '<span class="c_table-filter-hint-text-highlighted">\'not:\'</span>: Beginnt dein Wert mit diesem Präfix, dann wird er negiert. Dir werden nur noch die Zeilen angezeigt, dessen entsprechende Zelle den Wert NICHT enthält.\n' +
            '</div>\n' +
            '<div class="c_table-filter-hint-paragraph">\n' +
            '<span class="c_table-filter-hint-text-highlighted">\'only:\'</span>: Beginnt dein Wert mit diesem Präfix, werden dir nur noch die Zeilen angezeigt, dessen entsprechende Zelle den Wert enthält und wo es darüber hinaus keine weiteren Werte gibt. Speziell geeignet für Zellen mit potentiell mehreren Werten.\n' +
            '</div>\n' +
            '<div class="c_table-filter-hint-paragraph">\n' +
            '<span class="c_table-filter-hint-text-highlighted">\'&lt;:\'</span>: Beginnt dein Wert mit diesem Präfix, werden nur noch die Zeilen angezeigt, dessen entsprechender Zellenwert KLEINER ist als der angegebene. (Beta)\n' +
            '</div>\n' +
            '<div class="c_table-filter-hint-paragraph">\n' +
            '<span class="c_table-filter-hint-text-highlighted">\'&gt;:\'</span>: Beginnt dein Wert mit diesem Präfix, werden nur noch die Zeilen angezeigt, dessen entsprechender Zellenwert GRÖSSER ist als der angegebene. (Beta)\n' +
            '</div>\n' +
            '<div class="c_table-filter-hint-headline">- Gibt es die Möglichkeit Filterwerte zu kombinieren? -</div>\n' +
            '<div class="c_table-filter-hint-paragraph">\n' +
            'Es gibt den Modifikator <span class="c_table-filter-hint-text-highlighted">\':&:\'</span>, mit dem Regeln miteinander verbunden werden können.\n' +
            'Hat dein Filterwert beispielsweise die Struktur \'Vertrag:&:Foto\', werden nur noch die Zeilen angezeigt, deren Zellenwert sowohl \'Vertrag\' als auch \'Foto\' enthält.\n' +
            'Auf diese Weise lässt sich aushebeln, dass Filterwerte innerhalb einer Spalte nur unabhängig voneinander wirken, also als ODER-Verknüpfung.\n' +
            '</div>\n' +
            '<div class="c_table-filter-hint-paragraph">\n' +
            'Es können beliebig viele Werte kombiniert werden (\'Vertrag:&:Foto:&:Neu:&:Wichtig\'). \n' +
            '</div>\n' +
            '<div class="c_table-filter-hint-paragraph">\n' +
            'Der Modifikator funktioniert gleichermaßen in Verbindung mit <span class="c_table-filter-hint-text-highlighted">\'not:\'</span>. Durch \'not:Vertrag:&:Foto\' werden nur die Zeilen angezeigt, deren Zellenwert weder \'Vertrag\' noch \'Foto\' enthält. \n' +
            '</div>',
        toggleFullscreen: 'Vollbildmodus umschalten',
        filterConfigPlaceholder: 'Filtere {0}',
        defaultFilterPlaceholder: 'Filter hier eingeben',
        defaultTableEmpty: 'Es sind keine Einträge vorhanden',
        defaultFilterNoResults: 'Alle Einträge wurden gefiltert',
        spinnerText: 'Daten werden geladen',
        expandRow: 'Tabellenzeile ausklappen',
        collapseRow: 'Tabellenzeile einklappen',
        sets: {
            default: 'Standard-Ansicht',
            projectCoordinator: 'Projektkoordinations-Ansicht',
            contentManager: 'Contentmanagement-Ansicht',
            serviceProvider: 'Dienstleister-Ansicht'
        }
    },
    userHandler: {
        getUsersError: 'Beim Abrufen der Nutzerliste ist folgender Fehler aufgetreten:',
        getUserSettingsError: 'Beim Abrufen deiner nutzerspezifischen Informationen ist ein Fehler aufgetreten. Die Anwendung läuft nun mit den Standardeinstellungen. Fehler-Info:',
        updateUserSettingsError: 'Beim Aktualisieren deiner nutzerspezifischen Informationen ist folgender Fehler aufgetreten:'
    },
    downloadHandler: {
        downloadError: 'Beim Herunterladen der Datei ist ein Fehler aufgetreten: ',
        untitled: 'Unbenannt'
    },
    taskAssignmentHandler: {
        assignTaskError: 'Beim Zuweisen der Aufgabe \'{0}\' ist folgender Fehler aufgetreten:',
        assignTaskSuccess: {
            standard: 'Die Aufgabe \'{0}\' wurde dir erfolgreich zugewiesen',
            short: 'Aufgabe dir zugewiesen'
        },
        unAssignTaskError: 'Beim Aufheben der Zuweisung von Aufgabe \'{0}\' ist folgender Fehler aufgetreten:',
        unAssignTaskSuccess: {
            standard: 'Die Zuweisung von Aufgabe \'{0}\' wurde erfolgreich aufgehoben',
            short: 'Zuweisung der Aufgabe aufgehoben'
        },
        taskNotAssigned: 'Nicht zugewiesen - Jetzt schnell sein - Einige Nutzer schauen sich diese Aufgabe gerade an',
        taskAssignedTo: '\'{0}\' zugewiesen',
        taskAssignedToMe: 'Mir zugewiesen'
    },
    taskExecutionHandler: {
        saveFormError: 'Beim Speichern des Formulars \'{0}\' ist folgender Fehler aufgetreten:'
    },
    smartFormHelper: {
        formNotExisting: {
            standard: 'Für den Schlüssel \'{0}\' konnte kein entsprechendes Formular gefunden werden',
            short: 'Der gesuchte Formular-Typ existiert nicht'
        }
    },
    generatePdfPreviewHandler: {
        getContentListError: 'Beim Abrufen der Produktbestandteile ist folgender Fehler aufgetreten:',
        getPdfListError: 'Beim Abrufen der PDF-Vorschaudateien ist folgender Fehler aufgetreten:',
        generatePdfError: 'Beim Generieren der PDF-Vorschau ist folgender Fehler aufgetreten:',
        noPdfsFound: {
            standard: 'Es konnten keine PDFs für die Produktbestandteile gefunden werden',
            short: 'Keine PDFs gefunden'
        }
    },
    mainNavigation: {
        getUserInfoError: 'Beim Abrufen deiner Nutzerinformationen ist folgender Fehler aufgetreten:',
        saveUserPreferencesError: 'Beim Speichern deiner Nutzerpräferenzen ist folgender Fehler aufgetreten:',
        saveUserPreferencesSuccess: {
            standard: 'Deine nutzerspezifischen Benachrichtigungseinstellungen wurden erfolgreich gespeichert',
            short: 'Benachrichtigungseinstellungen gespeichert'
        },
        resetUserFilterSuccess: {
            standard: 'Die Filtereinstellungen wurden erfolgreich zurückgesetzt',
            short: 'Filter erfolgreich zurückgesetzt'
        },
        productList: 'Produktliste',
        productSearchPlaceholder: 'Neues Produkt hinzufügen',
        searchError: 'Bei der Suche nach \'{0}\' ist folgender Fehler aufgetreten:',
        productExists: {
            standard: 'Ein Produkt mit der Produktnummer \'{0}\' existiert bereits. Am besten suchst du es mit Hilfe des Filters in der Produktliste.',
            short: 'Produkt \'{0}\' existiert bereits'
        },
        productPreview: {
            conflictMessage: 'Das Produkt beinhaltet Konflikte, weswegen es in CorFlow nicht angelegt werden darf. Die Konflikte sind in der Vorschau hervorgehoben und geben dir per Mouseover genauere Details.',
            title: 'Produktvorschau',
            openProductWhenCreated: 'Produkt nach dem Hinzufügen öffnen',
            createProduct: 'Produkt anlegen',
            createProductSuccess: {
                standard: 'Das Produkt wurde erfolgreich angelegt',
                short: 'Produkt angelegt'
            },
            createProductError: 'Bei der Produktanlage ist folgender Fehler aufgetreten:'
        },
        lastProducts: 'Zuletzt verwendete Produkte',
        taskList: 'Aufgabenliste',
        administration: 'Administration',
        adminArea: 'Admin-Bereiche',
        adminTasks: 'Aufgabenübersicht',
        adminControlling: 'Controlling',
        playground: 'Spielwiese',
        gallery: 'Galerie',
        help: 'Brauchst du Hilfe?',
        language: 'DE',
        languageTooltip: 'Zur englischen Sprache wechseln',
        salutation: 'Schön dich zu sehen, {0}',
        currentReleaseLink: 'Link zu aktuellen Release-Notes',
        currentBranches: 'Veröffentlichte Branches',
        currentFrontendBranch: 'Frontend Branch',
        currentBackendBranch: 'Backend Branch',
        currentUser: 'Eingeloggt als',
        filterSettings: 'Filtereinstellungen',
        productListFilter: 'Filter der Produktliste',
        taskListFilter: 'Filter der Aufgabenliste',
        processMigrationFilter: 'Filter der Prozessmigration',
        productCompletionFilter: 'Filter des Produkt-Abschlusses',
        resetFilterToStandard: 'Standard wiederherstellen',
        systemSettings: 'Systemeinstellungen',
        setNotificationType: 'Reduzierte System-Meldungen',
        setContactMail: 'Kontakt E-Mail-Adresse',
        setContactMailHint: 'Hier kannst du eine alternative E-Mail-Adresse eingeben über die du kontaktiert werden kannst. Standardmäßig ist hier das E-Mail-Konto hinterlegt, das mit deinem Cornelsen Account verknüpft ist. Die hier hinterlegte E-Mail-Adresse wird auch für das Versenden von Benachrichtigungen verwendet.',
        notificationSettings: 'Benachrichtigungseinstellungen',
        setNotificationProduct: 'Produktzuweisungen',
        setNotificationProductHint: 'Erhalte eine E-Mail, wenn dir eine Rolle in einem Produkt zugewiesen wird.',
        setNotificationTask: 'Neue Aufgaben',
        setNotificationTaskHint: 'Erhalte eine E-Mail, wenn eine neue Aufgabe auf dich wartet.',
        setCancelTaskTermination: 'Aufgabenabschluss verhindern können',
        setCancelTaskTerminationHint: 'Erlaubt dir den Abschluss der letzten Aufgabe innerhalb von 10 Sekunden abzubrechen und in die Aufgabe zurückzukehren.'
    },
    noAccess: {
        pageTitle: 'Kein Zugriff',
        language: '@:mainNavigation.language',
        languageTooltip: '@:mainNavigation.languageTooltip',
        headerTitle: 'Nicht authentifiziert',
        headerSubtitle: 'Für die Nutzung von CorFlow fehlen dir erforderliche Rechte',
        howToGetAccess: 'Wie bekomme ich die erforderlichen Zugriffsrechte?',
        checkLogin: "Möglicherweise besitzt du bereits alle notwendigen Rechte, verwendest für die Anmeldung in CorFlow jedoch den falschen Account. Um das auszuschließen kannst du gerne auf den nachfolgenden Button klicken. Er leitet dich auf den Microsoft Login weiter, wo du dich bitte mit der von Cornelsen bereitgestellten E-Mail-Adresse (vorname.nachname{'@'}cornelsen.de) anmeldest.",
        toMicrosoftLogin: 'Zum Microsoft Login',
        stillNoAccess: 'Das hat keine Abhilfe geschaffen?',
        furtherActions: 'Solltest du weiterhin keinen Zugriff auf die Anwendung haben, können wir dir die Rechte dennoch geben, sobald diese beantragt wurden. Das Vorgehen haben wir dir nachfolgend beschrieben.',
        internalEmployee: 'Ich bin interner Angestellter',
        internalEmployeeHelp: 'Als interner Mitarbeiter hast du die Möglichkeit, den Zugang zu CorFlow ganz einfach über das IT-Service Portal zu bestellen. Nach erfolgter Genehmigung durch deinen Kostenstellenverantwortlichen kannst du dich anschließend in CorFlow anmelden. Nutze gerne den nachfolgenden Button, um direkt ins IT-Service-Portal weitergeleitet zu werden.',
        toServicePortal: 'Zum IT-Service Portal',
        externalServiceProvider: 'Ich bin externer Dienstleister',
        externalServiceProviderHelp: 'Wende dich als externer Dienstleister bitte an deinen direkten Ansprechpartner bei Cornelsen und bitte ihn darum, einen Zugang zu CorFlow für dich über das IT-Service Portal zu beantragen. Nach erfolgter Freigabe kannst du dich mit deiner Cornelsen E-Mail-Adresse in CorFlow anmelden.',
        conclusion: '<span>Wir hoffen, dass wir dir damit weiterhelfen konnten. ' +
            'Viele weitere Informationen kannst du jeder Zeit in der <a href="https://jira.cornelsen.de/plugins/servlet/desk/site/global/article/97730660/Zugangsberechtigung+CorFlow" title="https://jira.cornelsen.de/plugins/servlet/desk/site/global/article/97730660/Zugangsberechtigung+CorFlow" target="_blank">Wissensdatenbank</a> nachlesen. ' +
            'Bei Fragen rund um die Bestellung von Services oder Login-Daten, wende dich bitte direkt an den <a href="' + "mailto:hilfe{'@'}cornelsen.de" + '" title="' + "hilfe{'@'}cornelsen.de" + '">User Help Desk</a>. ' +
            'Alle weiteren Fragen richte sehr gerne an den <a href="' + "mailto:publishing-support{'@'}cornelsen.de" + '" title="' + "publishing-support{'@'}cornelsen.de" + '">Publishing-Support</a> oder nutze das <a href="https://cornelsen.refined.site/portal/3" target="_blank">Publishing-Support-Portal</a>.<br />' +
            '<br />' +
            'Vielen Dank und liebe Grüße<br />' +
            'Dein CorFlow Team 😊</span>'
    },
    productList: {
        headline: '@:mainNavigation.productList',
        getProductListError: 'Beim Abrufen der Produktliste ist folgender Fehler aufgetreten:',
        getProductStateError: 'Beim Abrufen des Produktstatus ist folgender Fehler aufgetreten:',
        table: {
            filterPlaceholder: 'Schnellfilter Produktliste',
            tableEmpty: 'Keine Produkte vorhanden',
            filterNoResults: 'Alle Produkte wurden gefiltert',
            developmentCompleted: 'Produktentwicklung abgeschlossen',
            hasErrors: 'Produkt beinhaltet Fehler',
            noErrors: 'Alles in Ordnung',
            productNumber: 'Produktnummer',
            isbn: 'ISBN',
            shortTitle: 'Kurztitel',
            pspElement: 'PSP-Element',
            profitCenter: 'Profit-Center',
            projectCoordinators: 'Projektkoordination',
            involved: 'Projektkoordination / Redaktion',
            serviceProviders: 'XML- und Satz-Dienstleister',
            briefingDate: 'Briefingtermin',
            dataDelivery: 'Datenabgabe',
            releaseDate: 'Erscheinungstermin',
            productState: 'Produktstatus',
            openProduct: 'Produkt öffnen',
            projectCoordinator: 'Projektkoordination',
            internalEditor: 'Redaktion',
            externalEditor: 'Redaktion (extern)',
            productForMe: 'Meine Produkte',
            productForOthers: 'Produkte anderer'
        }
    },
    product: {
        getProductStateError: '@:productList.getProductStateError',
        getProductInformationError: 'Beim Abrufen der Produktinformationen ist folgender Fehler aufgetreten:',
        startDevelopment: 'Entwicklungsprozess starten',
        inDevelopment: 'Im Entwicklungsprozess',
        isCompleted: 'Produktentstehung abgeschlossen',
        startFinalization: 'Finalisierungsprozess starten',
        inFinalization: 'Im Finalisierungsprozess',
        startDevelopmentPopup: {
            ZSTP: {
                title: 'Hinweis zum Briefing-Formular',
                text: 'Sofern die Redaktion bereits die Möglichkeit hat Produktbestandteile anzulegen und mit Abgabedaten zu versehen, sollte das geschehen bevor du die Aufgabe \'Dienstleister-Briefing durchführen\' beginnst. Alle Informationen an Produktbestandteilen, die bis zu diesem Zeitpunkt gepflegt wurden, werden automatisch ins Briefingformular übernommen und müssen nicht manuell nachgetragen werden.'
            },
            ZUFE: {
                title: 'Hinweis zur Produktentwicklung',
                text: 'Für unverkäufliche Materialien werden keine XML-Aufgaben erzeugt.'
            },
            ZSTN: {
                title: '@:product.startDevelopmentPopup.ZSTP.title',
                text: '@:product.startDevelopmentPopup.ZSTP.text'
            },
            UNKNOWN: {
                name: 'Inkompatible Materialart',
                title: 'Inkompatible Materialart',
                text: 'Dieses Produkt kann aktuell nicht über CorFlow abgewickelt werden, da es über eine Materialart verfügt, für die es keine kompatiblen Prozesse gibt.'
            },
            confirmText: 'Entwicklung starten',
            cancelText: 'Start abbrechen'
        },
        startFinalizationPopup: {
            title: 'Produktentwicklung wird abgeschlossen',
            text: 'Sobald du die Finalisierung gestartet hast, können keine Änderungen mehr an den Produktbestandteilen vorgenommen oder neue hinzugefügt werden. Bist du sicher, dass der Produktinhalt vollständig ist?',
            confirmText: 'Ja, starten',
            cancelText: 'Start abbrechen'
        },
        startDevelopmentSuccess: {
            standard: 'Der Produktentwicklungsprozess wurde erfolgreich gestartet',
            short: 'Produktentwicklung gestartet'
        },
        startFinalizationSuccess: {
            standard: 'Der Produktfinalisierungsprozess wurde erfolgreich gestartet',
            short: 'Produktfinalisierung gestartet'
        },
        startDevelopmentError: 'Beim Starten der Produktentwicklung ist folgender Fehler aufgetreten:',
        startFinalizationError: 'Beim Starten der Produktfinalisierung ist folgender Fehler aufgetreten:',
        subNavigation: {
            cockpit: 'Cockpit',
            info: 'Produktinfos',
            contents: 'Bestandteile',
            schedule: 'Zeitplan',
            assetOrders: 'Assetbestellung',
            processHistory: 'Produkthistorie'
        },
        services: {
            getServicesError: 'Beim Abrufen der Services für dieses Produkt ist folgender Fehler aufgetreten:',
            executeServiceError: 'Beim Starten des Service ist folgender Fehler aufgetreten:',
            executeServiceSuccess: {
                standard: 'Der Service \'{0}\' wurde erfolgreich gestartet!',
                short: 'Service gestartet'
            },
            toggleToIndexCardView: 'Zur Karteikartenansicht wechseln',
            toggleToListView: 'Zur Listenansicht wechseln',
            serviceIsActive: 'Service läuft/ist aktiv',
            serviceIsInactive: 'Service ist inaktiv',
            executeService: 'Service starten/ausführen',
            hint: 'Hinweis',
            description: 'Beschreibung',
            execution: 'Ausführung',
            serviceNotExecuted: 'Wurde noch nicht gestartet.',
            serviceInExecution: 'Wurde noch nicht abgeschlossen.',
            serviceCompleted: 'Wurde bereits abgeschlossen.',
            serviceExecutedXTimes: 'Wurde {0} Mal ausgeführt.',
            titles: {
                PDF_PREVIEW: 'Vorschau-PDF generieren',
                AUTHOR_CONTRACT: 'Autoren- und Herausgeberverträge beauftragen',
                ILLUSTRATOR_CONTRACT: 'Grafiker*innen/ Illustrator*innen beauftragen',
                PHOTOGRAPHER_CONTRACT: 'Fotograf*innen beauftragen',
                EINREICHUNG: 'Einreichung starten',
                TEST_PROCESS: 'Test-123'
            },
            descriptions: {
                PDF_PREVIEW: 'Hier kannst du ein PDF aus einer Auswahl der zuletzt übermittelten PDF-Dateien aller Produktbestandteile zusammenfügen.',
                AUTHOR_CONTRACT: 'Hier werden die nötigen Angaben abgefragt und ans Vertragsmanagement geschickt. Dabei wird auch eine Titeldoku abgelegt.',
                ILLUSTRATOR_CONTRACT: 'Hier werden die nötigen Angaben abgefragt und ans Medienmanagement geschickt. Die Grafiken selbst beauftragst du wie bisher.',
                PHOTOGRAPHER_CONTRACT: 'Hier werden die nötigen Angaben abgefragt und ans Medienmanagement geschickt. Die Fotos selbst beauftragst du wie bisher.',
                BRIEFING: 'Bereite das Dienstleisterbriefing mit der Redaktion vor und führe es dann mit dem Satzdienstleister durch.',
                EINREICHUNG: 'Hier kannst du die Einreichung für ein Produkt starten.',
                TEST_PROCESS: 'Im Zweifel ist die Schuldige immer Shania Twain...'
            },
            infoMessages: {
                generatePdfInfo: 'Kann jederzeit ausgeführt werden sobald PDFs vorliegen',
                commissioningInfo6: 'Bis 6 Wochen vor der lizenzrechtlichen Imprimatur abzuschließen',
                commissioningInfo12: 'Bis 12 Wochen vor der lizenzrechtlichen Imprimatur abzuschließen',
                commissioningOverdue: 'Sollten noch Beauftragungen nötig sein, sind diese überfällig',
                commissioningImpossible: 'Beauftragung nach dem Termin "LR Imprimatur" nicht mehr möglich',
                contractOverdue: 'Sollten noch Verträge nötig sein, ist die Beauftragung überfällig',
                contractImpossible: 'Vertragserstellung nach Termin "LR Imprimatur" nicht mehr möglich',
                executePriorToDevelopment: 'Muss ausgeführt werden, bevor die Arbeit am Produkt beginnen kann',
                createXmlData: 'Der Dienstleister beginnt mit Abschluss von "Realisierung Print"'
            },
            generatePdfPreview: {
                modalTitle: 'PDF-Dateien für Vorschau anordnen',
                pdfConflictWarning: 'Produktbestandteile beinhalten Konflikte, wodurch die Vorschau unvollständig sein kann',
                startPdfGeneration: 'Vorschau-PDF-Generierung starten',
                generatingPreviewPdf: 'Die PDF-Vorschau wird gerade generiert. Dieser Vorgang kann, je nach Größe und Inhalt der einzelnen Dateien, einen Moment in Anspruch nehmen. Bitte warte einen Augenblick.'
            },
            table: {
                status: 'Status',
                title: 'Service',
                tableEmpty: 'Aktuell stehen für dich keine Services zur Verfügung'
            }
        },
        tasks: {
            getTasksError: 'Beim abrufen der verfügbaren Aufgaben für dieses Produkt ist folgender Fehler aufgetreten:',
            headline: 'Produktbezogene Aufgaben',
            table: {
                tableEmpty: 'Aktuell sind keine Aufgaben in diesem Produkt vorhanden',
                taskName: '@:taskList.table.taskName',
                taskContext: '@:taskList.table.taskContext',
                taskRole: '@:taskList.table.taskRole',
                claimAndEditTask: '@:taskList.table.claimAndEditTask'
            }
        },
        details: {
            getProductInformationError: '@:product.getProductInformationError',
            approvedEinreichungCount: 'Genehmigt: {0} von {1}',
            noGroupInformationPlaceholder: 'Für dieses Produkt existieren bislang keine Einträge für diese Gruppe',
            relationsCount: "Eingehend: {0} {'|'} Ausgehend: {1}",
            relationTypes: {
                DEMO: 'Demoversion',
                DOWNLOAD: 'Download',
                COMPLEMENTARY_MEDIUM: '@:product.details.relationTypes.BEGLEITMED',
                BEGLEITMED: 'Begleitmedium',
                APP_ZUSMAT: 'App-Zusatzmaterial (ist/hat App-Zusatzmaterial)',
                DIGZUSMAT: 'Digitales Zusatzmaterial',
                IDENTISCH: 'Inhaltlich identisch',
                IDENT_TW: 'Inhaltlich teilweise identisch',
                EB_SCOOK: 'E-Book (CV)',
                DLPDF: 'Download-PDF',
                W_MITTEL: 'Werbemittel',
                CODE: 'Code',
                U_MANAGER: 'Unterrichtsmanager',
                NUTZ_PROD: 'Nutzungsprodukt',
                EB_Z1: 'E-Book Plus',
                PREDECESSOR_SUCCESSOR: 'Ist Nachfolger von'
            },
            incomingRelation: 'Eingehende Produktbeziehung',
            outgoingRelation: 'Ausgehende Produktbeziehung',
            titles: {
                links: 'Verlinkungen',
                baseInformation: 'Produktdaten',
                equipmentData: 'Ausstattungsdaten',
                internalContributors: 'Interne Mitwirkende',
                externalContributors: 'Externe Mitwirkende',
                productApprovals: 'Status der Einreichungen',
                relationData: 'Produktbeziehungen'
            },
            fields: {
                measurementUnits: {
                    retailPrice: ' EUR',
                    breite: ' cm',
                    hoehe: ' cm',
                    rueckenStaerke: ' mm',
                    gesamtUmfang: ' Seiten',
                    totalPageCount: ' Seiten'
                },
                linkToWwaLabel: 'WoodWing Assets',
                linkToWwaValue: 'Krümelpfad in WoodWing Assets öffnen',
                linkToDashboardLabel: 'Vertragsdashboard',
                linkToDashboardValue: 'Verträge im Vertragsdashboard einsehen',
                linkToRmsLabel: 'Compliance Check im RMS (ab 12 Monate vor SOLL-ET)',
                linkToRmsValue: 'RMS-Compliance-Check-Informationen einsehen',
                linkToAiPortalLabel: 'Cornelsen AI Portal',
                linkToAiPortalValue: 'Cornelsen AI Portal öffnen',
                isbn: 'ISBN',
                productNumber: 'SAP-Produktnummer',
                orderNumber: 'Bestellnummer',
                shortTitle: 'Kurztitel',
                title: 'Titel',
                gradeLevels: 'Klassenstufen',
                retailPrice: 'Ladenpreis (D)',
                type: 'Produktart',
                productionLine: 'Produktionsstrecke',
                series: 'Reihe',
                seriesEdition: 'Reihenausgabe',
                seriesEditionKey: 'Reihenschlüssel',
                profitCenter: 'Profit-Center',
                plannedReleaseDate: 'SOLL-Erscheinungstermin',
                edition: 'PSP-Element der Auflage',
                druckerei: 'Druckerei',
                druckAuflage: 'Druckauflage',
                druckAuflagenStatus: 'Status der Druckauflage',
                breite: 'Formatbreite',
                hoehe: 'Formathöhe',
                rueckenStaerke: 'Rückenstärke',
                gesamtUmfang: 'Gesamtumfang (SAP)',
                umfang: 'Umfang',
                farbigkeit: 'Farbigkeit',
                editors: 'Redaktion',
                leadEditors: 'Redaktionsleitung',
                marketingManagers: 'Marketingmanagement',
                productManagers: 'Produktmanagement',
                contractManagers: 'Vertragsmanagement',
                authors: 'Autorenteam',
                publishers: 'Herausgeber*innen',
                country: 'Land',
                federalState: 'Bundesland',
                schoolType: 'Schulform',
                subject: 'Schulfach',
                gradeLevel: 'Klassenstufe',
                schoolSubject: 'Schulfach',
                status: 'Status',
                productState: 'Produktstatus',
                lastStatusUpdate: 'Letzte Statusänderung',
                materialType: 'Materialart',
                linkToStyleGuide: 'Styleguide',
                poolProduction: 'Fundusproduktion?',
                testEditionPlanned: 'Prüfauflage?',
                testEditionDataDeliveryDate: 'Druckdatenlieferung für Prüfauflage',
                isEinreichungNecessary: 'Einreichung?',
                dateOfEinreichung: 'Zeitpunkt der Datenlieferung für Einreichfassung',
                totalPageCount: 'Gesamtumfang (Briefing)',
                planedSubmissionDate: 'SOLL-Einreichtermin',
                serviceProviderReference: 'Referenz im System des Dienstleisters',
                seriesWordTemplate: 'Reihen-Wordtemplate'
            },
            getProductWwaPathError: 'Beim Abrufen des WWA-Krümelpfad ist folgender Fehler aufgetreten:'
        },
        members: {
            getRolesError: 'Beim Abrufen der Rollen des Produkts ist folgender Fehler aufgetreten:',
            getUsersError: 'Beim Abrufen der Nutzerliste ist folgender Fehler aufgetreten:',
            addUserError: 'Beim Hinzufügen des Nutzers \'{0}\' zu Rolle \'{1}\' ist folgender Fehler aufgetreten:',
            addUserSuccess: {
                standard: 'Der Nutzer \'{0}\' wurde erfolgreich den Mitgliedern der Rolle \'{1}\' hinzugefügt',
                short: '\'{0}\' hinzugefügt'
            },
            removeMemberError: 'Beim Entfernen des Mitglieds \'{0}\' von Rolle \'{1}\' ist folgender Fehler aufgetreten:',
            removeMemberSuccess: {
                standard: 'Das Mitglied \'{0}\' wurde erfolgreich aus der Rolle \'{1}\' entfernt',
                short: '\'{0}\' entfernt'
            },
            removeMemberPopup: {
                popupTitle: '\'{0}\' hat noch Arbeit zu erledigen',
                popupText: '\'{0}\' sind noch offene Aufgaben dieses Produkts in seiner Rolle als \'{1}\' zugewiesen und kann daher nicht entfernt werden. Bitte melde dich bei \'{0}\' und bitte sie oder ihn, die Aufgaben abzugeben oder zu beenden. Andernfalls, wende dich für weitere Hilfe an den Publishing-Support unter ' +
                    '<a href="' + "mailto:publishing-support{'@'}cornelsen.de" + '">' + "publishing-support{'@'}cornelsen.de" + '</a>.',
                popupConfirmText: 'Ok, verstanden'
            },
            headline: 'Mitgliederverwaltung',
            table: {
                role: 'Rolle',
                members: 'Mitglieder',
                tableEmpty: 'Weder Rollen noch Mitglieder sind für dieses Produkt definiert',
                addMember: 'Mitglied hinzufügen',
                addMyself: 'Mich hinzufügen',
                selectMemberPlaceholder: 'Nutzer auswählen'
            }
        },
        contents: {
            getContentListError: 'Beim Abrufen der Produktbestandteile ist folgender Fehler aufgetreten:',
            getContentTypesError: 'Beim Abrufen der Bestandteil-Typen ist folgender Fehler aufgetreten:',
            emptyValueWarning: {
                standard: 'Der Produktbestandteil wird erst gespeichert, wenn alle Pflichtfelder befüllt wurden. Bis dahin eingetragene Änderungen gehen beim Verlassen der Seite verloren!',
                short: 'Änderung wird übernommen, sobald fehlende Pflichtfelder befüllt sind'
            },
            updateContentWarning: {
                standard: 'Der Produktbestandteil kann nicht mit leeren Werten abgespeichert werden',
                short: 'Der Wert darf nicht leer sein'
            },
            updateContentError: 'Beim Speichern des Produktbestandteils \'{0}\' ist folgender Fehler aufgetreten:',
            updateContentSuccess: {
                standard: 'Der Produktbestandteil \'{0}\' wurde erfolgreich gespeichert',
                short: '\'{0}\' gespeichert'
            },
            switchContentTypeWarning: {
                standard: "Der Typ des Produktbestandteils '{0}' kann erst übernommen werden, wenn du die Pflichtfelder '@:{'product.contents.table.pages'}' und '@:{'product.contents.table.submissionDate'}' befüllt hast.",
                short: 'Bitte befülle die Pflichtfelder für die Änderung des Typs'
            },
            startContentImpossiblePopup: {
                title: 'Prozess-Ausführung nicht möglich',
                text: 'Der Prozess für diesen Produktbestandteil kann aktuell nicht gestartet werden, da nicht alle Pflichtfelder befüllt sind. Bitte gib die geplante Seitenanzahl sowie das voraussichtliche Datum der Manuskriptabgabe an und versuche es anschließend erneut.',
                cancelText: 'Okay, machen wir so'
            },
            startContentPopup: {
                title: 'Umsetzung wird gestartet',
                text: 'Die Umsetzung dieses Produktbestandteils mit dem Dienstleister zu starten führt dazu, dass der Produktbestandteil nicht mehr gelöscht und der Prozess in CorFlow nicht mehr gestoppt werden kann. Bist du sicher, dass du die Umsetzung dieses Produktbestandteils jetzt starten möchtest?',
                confirmText: 'Ja, starten',
                cancelText: 'Nein, noch nicht'
            },
            startContentError: 'Beim Start der Umsetzung des Produktbestandteils \'{0}\' ist folgender Fehler aufgetreten:',
            startContentSuccess: {
                standard: 'Die Umsetzung des Produktbestandteils \'{0}\' wurde erfolgreich gestartet',
                short: '\'{0}\' gestartet'
            },
            removeContentError: 'Beim Löschen des Produktbestandteils \'{0}\' ist folgender Fehler aufgetreten:',
            removeContentSuccess: {
                standard: 'Der Produktbestandteil \'{0}\' wurde erfolgreich gelöscht',
                short: '\'{0}\' gelöscht'
            },
            addContent: 'Produktbestandteil anlegen',
            pageCount: 'Gesamtseiten-Anzahl:',
            table: {
                noContentCreation: 'Prozess- oder rechtebedingt ist die Anlage von Produktbestandteilen nicht möglich!',
                filterPlaceholder: 'Schnellfilter Produktbestandteile',
                tableEmpty: 'Keine Produktbestandteile vorhanden',
                filterNoResults: 'Alle Produktebestandteile wurden gefiltert',
                newLabel: 'Neu',
                contentName: 'Bezeichnung',
                contentType: 'Typ',
                pages: 'Geplante Seitenanzahl',
                submissionDate: 'Manuskriptabgabe',
                testRound: 'Prüfrunde',
                startContent: 'Umsetzung starten',
                contentStarted: 'Prozess des Bestandteils befindet sich in Ausführung',
                contentFinished: 'Prozess des Bestandteils abgeschlossen',
                removeContent: 'Bestandteil löschen',
                contentNamePlaceholder: 'Bezeichnung eingeben',
                contentTypePlaceholder: 'Typ auswählen'
            },
            newContent: {
                label: '@:product.contents.addContent',
                contentNamePlaceholder: '@:product.contents.table.contentNamePlaceholder',
                contentTypePlaceholder: '@:product.contents.table.contentTypePlaceholder',
                addContentSuccess: {
                    standard: '@:product.contents.updateContentSuccess.standard',
                    short: '@:product.contents.updateContentSuccess.short'
                },
                addContentError: '@:product.contents.updateContentError',
                saveAndContinue: 'Speichern und weiteren anlegen',
                saveAndExit: 'Speichern und schließen'
            }
        },
        schedule: {
            getMilestonesError: 'Beim Abrufen der Vorgänge ist folgender Fehler aufgetreten:',
            getContentListError: '@:product.contents.getContentListError',
            saveMilestoneDeadlineError: 'Beim Speichern des Endtermins für Vorgang \'{0}\' ist folgender Fehler aufgetreten:',
            saveMilestoneDeadlineSuccess: {
                standard: 'Der Endtermin für Vorgang \'{0}\' wurde erfolgreich gespeichert',
                short: 'Endtermin gespeichert'
            },
            replyMilestoneError: 'Bei der Rückmeldung des Vorgangs \'{0}\' an SAP ist folgender Fehler aufgetreten:',
            replyMilestoneSuccess: {
                standard: 'Der Vorgang \'{0}\' und etwaige Untervorgänge wurden erfolgreich an SAP zurückgemeldet',
                short: 'Vorgang an SAP zurückgemeldet'
            },
            reportingHiddenKey: 'tabg',
            reportingCompletedKey: 'rückgemeldet',
            milestoneDoneKey: 'erledigt',
            legalLicenceImprimaturPopup: {
                title: "Vorgang '@:{'product.schedule.table.processNames.LEGAL_LICENCE_IMPRIMATUR'}' wird zurückgemeldet",
                text: 'Damit werden sämtliche Punkte der <a href="https://cornelsen-cdp.iviews.empolisservices.com/document/version/Published/confluence-document-56740180/chapter/149479416/" target="_blank">Checkliste Lizenzrechtliche Imprimatur</a> als erfüllt definiert. Möchtest du mit der Rückmeldung des Vorgangs fortfahren und damit bestätigen, dass die Punkte der Checkliste erfüllt wurden?',
                confirmButtonText: 'Ja, habe ich gelesen und kann ich bestätigen'
            },
            table: {
                filterPlaceholder: 'Schnellfilter Vorgänge',
                tableEmpty: 'Keine Vorgänge vorhanden',
                filterNoResults: 'Alle Vorgänge wurden gefiltert',
                type: 'Typ',
                name: 'Bezeichnung',
                replyDeadline: 'Termin',
                status: 'Status',
                deadlineReachedIn: '{0} Tage verbleiben',
                deadlineReached: 'Endtermin erreicht!',
                deadlineExceeded: 'Endtermin seit {0} Tagen überschritten!',
                deadlineChangePossible: 'Berechneter Endtermin - Kann manuell geändert werden',
                deadlineChangedManually: 'Endtermin am {0} manuell von \'{1}\' geändert',
                productContentDataProvided: 'Daten für Realisierung bereitgestellt',
                productContentFinished: 'Produktbestandteil fertiggestellt',
                productContentInProgressNoDeadline: 'Produktbestandteil in Bearbeitung - Kein Endtermin',
                reportingStatusDone: 'Rückgemeldet in SAP',
                reportingStatusDoneAtBy: 'Rückgemeldet am {0} von \'{1}\'',
                milestoneDoneAt: 'Vorgang erledigt am {0}',
                replyMilestone: 'Vorgang rückmelden',
                processNames: {
                    LEGAL_LICENCE_IMPRIMATUR: 'Lizenzrechtliche Imprimatur',
                    unknown: 'Unbekannte Prozessbezeichnung'
                },
                itemTypes: {
                    SCHEDULE: 'Termin',
                    CONTENT: 'Produktbestandteil'
                }
            }
        },
        assetOrders: {
            getOrdersError: 'Beim Abrufen der Assetbestellungen ist folgender Fehler aufgetreten:',
            saveOrderError: 'Beim Speichern der Assetbestellung ist folgender Fehler aufgetreten:',
            saveOrderSuccess: {
                standard: 'Deine Assetbestellung wurde erfolgreich gespeichert. Durch die Übermittlung an das RMS kann es einen Moment dauern, bis die Bestellung vollständig in der Liste angezeigt wird.',
                short: 'Assetbestellung gespeichert'
            },
            getOrderInfoError: 'Beim Abrufen der Informationen zu Assetbestellung \'{0}\' ist folgender Fehler aufgetreten:',
            getFilesOfOrderError: 'Beim Abrufen der Dateien der Bestellung ist folgender Fehler aufgetreten:',
            addFileToOrderError: 'Beim Hochladen der Dateien ist folgender Fehler aufgetreten:',
            addFileToOrderSuccess: {
                standard: 'Die Dateien wurden erfolgreich hochgeladen und verknüpft',
                short: 'Dateien hochgeladen'
            },
            removeFileFromOrderError: 'Beim Löschen der Datei ist folgender Fehler aufgetreten:',
            removeFileFromOrderSuccess: {
                standard: 'Die Datei wurde erfolgreich gelöscht',
                short: 'Datei gelöscht'
            },
            addCommentOnOrderError: 'Beim Speichern des Kommentars an Assetbestellung \'{0}\' ist folgender Fehler aufgetreten:',
            addCommentOnOrderSuccess: {
                standard: 'Der Kommentar wurde erfolgreich an der Assetbestellung \'{0}\' gespeichert',
                short: 'Kommentar gespeichert'
            },
            switchNeedToTalkOrderError: 'Beim An- bzw. Abmelden des Gesprächsbedarfs für Assetbestellung \'{0}\' ist folgender Fehler aufgetreten:',
            switchNeedToTalkOrderSuccess: {
                standard: 'Der Gesprächsbedarf für Assetbestellung \'{0}\' wurde erfolgreich an- bzw. abgemeldet',
                short: 'Status für Gesprächsbedarf geändert'
            },
            removeOrderError: 'Beim Löschen der Assetbestellung \'{0}\' ist folgender Fehler aufgetreten:',
            removeOrderSuccess: {
                standard: 'Die Assetbestellung \'{0}\' wurde erfolgreich gelöscht',
                short: 'Assetbestellung gelöscht'
            },
            productNumberOfOriginalError: 'Deine Eingabe ist inkompatibel. Bitte verwende für das Feld nur ein gültiges ISBN-13 Format.',
            identicalProductsError: 'Deine Eingabe ist inkompatibel. Bitte verwende für das Feld nur kommaseparierte Produktnummern (4-13 Ziffern), ohne Leerzeichen oder Umbrüche. Inkompatible Werte können leider nicht an das RMS übermittelt werden, da sie dort sonst nicht weiterverarbeitet werden können.',
            repeatOrderButtonLabel: 'Assetbestellung duplizieren',
            orderNotEditable: 'Die Bestellung kann nicht bearbeitet werden!',
            assetTypes: {
                AUDIO: 'Audio',
                PICTURE: 'Bild',
                TEXT: 'Text',
                VIDEO: 'Video',
                UNKNOWN: 'Bestellung wird mit dem RMS synchronisiert...'
            },
            table: {
                filterPlaceholder: 'Schnellfilter Assetbestellungen',
                tableEmpty: 'Aktuell sind keine Assetbestellungen in diesem Produkt vorhanden',
                filterNoResults: 'Alle Assetbestellungen wurden gefiltert',
                assetType: 'Typ',
                motif: 'Motiv/Titel',
                usage: 'Ort der Verwendung',
                source: 'Von',
                reference: 'Fundstelle',
                licensing: 'VG',
                licensingToolTip: 'Verwertungsgesellschaft/Lizenzierungsart',
                createdAt: 'Erstellt',
                updatedAt: 'Geändert',
                comments: 'Kommentare',
                attachments: 'Anhänge',
                flagged: '⚑',
                state: 'Status',
                lastStatusChange: 'Status aktualisiert am: {0}',
                clarificationNecessary: 'Klärungsbedarf angemeldet',
                noClarificationNecessary: 'Kein Klärungsbedarf',
                setClarification: 'Klärungsbedarf anmelden',
                unsetClarification: 'Klärungsbedarf abmelden',
                copyOrder: 'Bestellung duplizieren',
                editOrder: 'Bestellung bearbeiten',
                removeOrder: 'Bestellung löschen'
            },
            modal: {
                commentHistory: 'Kommentarverlauf zur Bestellung',
                noCommentHistory: 'Hier geht bald die Post ab!<br />Sei der/die Erste und schreibe einen Kommentar zu dieser Bestellung.',
                enterCommentHere: 'Schreibe deinen Kommentar... (Drücke Umschalt + Eingabe für einen Zeilenumbruch.)'
            },
            abortUnsavedChangesPopup: {
                title: 'Übermittlung deiner Änderungen abbrechen?',
                text: '<p>Möchtest du die Bestellung verlassen, ohne deine Änderungen ans RMS zu übermitteln? Dann wähle \'Bestellung verlassen\'.</p><p>Wenn du die Bestellung verlassen, dabei aber deine geänderten Angaben an das Medienmanagement übermitteln willst, aktiviere vorher die Checkbox \'Änderungen übermitteln\'.</p>',
                checkboxLabel: 'Änderungen übermitteln',
                confirmText: 'Bestellung verlassen'
            },
            placeNewOrder: 'Neue Bestellung aufgeben',
            backToOrderList: 'Schließen und zurück zur Liste der Bestellungen'
        },
        processHistory: {
            getProcessHistoryError: 'Beim Abrufen der Historieneinträge ist folgender Fehler aufgetreten:',
            deletedProductContent: '<i>Gelöschter Produktbestandteil</i>',
            historyEntryDataObjectTypes: {
                document: 'Datei bereitgestellt',
                custom_form_document: 'Datei bereitgestellt',
                hyperlink: 'Link bereitgestellt',
                text: 'Text hinterlegt',
                unknown: 'Unbekannter Typ'
            },
            correctionRoundZeroRejection: 'Korrekturrunde {0}, ohne Ablehnungsrunde',
            correctionRoundOrRejectionRound: 'Korrekturrunde oder Ablehnungsrunde',
            correctionRoundWithRejection: 'Korrekturrunde {0}, Ablehnungsrunde {1}',
            table: {
                filterPlaceholder: 'Schnellfilter Historieneinträge',
                tableEmpty: 'Keine Historieneinträge vorhanden',
                filterNoResults: 'Alle Historieneinträge wurden gefiltert',
                type: 'Typ',
                taskName: 'Aufgabenname',
                contentName: 'Produktbestandteil',
                testRound: 'Korrekturrunde',
                createdAt: 'Erstellt am',
                userName: 'Erstellt durch',
                annotation: 'Anmerkung',
                downloadFiles: 'Dokumente des Eintrags als Zip herunterladen',
                noDataProvided: 'Zu dieser Aufgabe können (noch) keine weiteren Inhalte angezeigt werden. Wir arbeiten daran! 🔨⚠️'
            },
            expandableContent: {
                DOCUMENT: 'Bereitgestellte Datei',
                CUSTOM_FORM_DOCUMENT: 'Bereitgestellte Datei',
                TEXT: 'Verfasster Kommentar',
                HYPERLINK: 'Bereitgestellter Link',
                UNKNOWN: 'Unbekannter Anhangs-Typ',
                conceptDescriptionUpload: 'Konzeptionsbeschreibung für das Produkt',
                bindingOverallConceptUpload: 'Gesamtkonzept für die Erfüllung eines Bildungsplan-Standards',
                previewOfContinuedEditionUpload: 'Vorausschau auf den Folgeband',
                foreignLanguageOverallConceptUpload: 'Gesamtkonzeption für die Reihe',
                transcriptUpload: 'Transkripte für noch nicht vorliegende Audios oder Videos',
                noticeOfApprovalUpload: 'Bescheid',
                assessmentUpload: 'Gutachten/Monita',
                stornoUpload: 'Stornoschreiben',
                changedPages: 'Geänderte Seiten',
                editorialStaffStatement: 'Redaktionelle Stellungnahme'
            }
        }
    },
    taskList: {
        headline: '@:mainNavigation.taskList',
        tasks: 'Aufgaben',
        hiddenTasks: 'Ausgeblendete Aufgaben',
        getTaskListError: 'Beim Abrufen der Aufgabenliste ist folgender Fehler aufgetreten:',
        taskNotProcessable: 'Die Aufgabe kann nicht bearbeitet werden!',
        table: {
            filterPlaceholder: 'Schnellfilter Aufgabenliste',
            filterNoResults: 'Alle Aufgaben wurden gefiltert',
            taskName: 'Aufgabenname',
            productNumber: 'Produktnummer',
            isbn: 'ISBN',
            profitCenter: 'Profit-Center',
            taskContext: 'Aufgabenkontext',
            briefingDate: 'Briefingtermin',
            releaseDate: 'Erscheinungstermin',
            creationDate: 'Erstellt am',
            taskRole: 'Bearbeiter / Rolle',
            tasksForMe: 'Aufgaben für mich',
            tasksForOthers: 'Aufgaben für andere',
            claimAndEditTask: 'Aufgabe bearbeiten'
        },
        visible: {
            setTaskHiddenError: 'Beim Ausblenden der Aufgabe ist folgender Fehler aufgetreten:',
            setTaskHiddenSuccess: {
                standard: "Die Aufgabe wurde erfolgreich ausgeblendet und befindet sich nun im Reiter '@:{'taskList.hiddenTasks'}'",
                short: 'Aufgabe ausgeblendet'
            },
            table: {
                tableEmpty: 'Aktuell sind keine Aufgaben in deinen Produkten vorhanden',
                hideTask: 'Aufgabe ausblenden'
            },
            backToTotalTaskList: 'Zurück zur Gesamtliste',
            timeUntilRefresh: 'Prüfe auf neue Aufgaben in: {0}'
        },
        hidden: {
            setTaskVisibleError: 'Beim Einblenden der Aufgabe ist ist folgender Fehler aufgetreten:',
            setTaskVisibleSuccess: {
                standard: "Die Aufgabe wurde erfolgreich eingeblendet und befindet sich nun im Reiter '@:{'taskList.tasks'}'",
                short: 'Aufgabe eingeblendet'
            },
            table: {
                tableEmpty: 'Aktuell sind keine ausgeblendeten Aufgaben vorhanden',
                showTask: 'Aufgabe einblenden'
            }
        }
    },
    task: {
        getTaskInfoError: 'Beim Abrufen der Aufgabeninformationen ist folgender Fehler aufgetreten:',
        completeTaskError: 'Beim Beenden der Aufgabe ist folgender Fehler aufgetreten:',
        completeTaskSuccess: {
            standard: 'Die Aufgabe wurde erfolgreich abgeschlossen',
            short: 'Aufgabe abgeschlossen'
        },
        finishTask: 'Aufgabe abschließen',
        returnTask: 'Aufgabe zurückgeben',
        originTitles: {
            TaskList: 'Zurück zur Aufgabenliste',
            ProductList: 'Zurück zur Produktliste',
            ProductCockpit: 'Zurück zum Cockpit'
        },
        taskDescription: {
            expandDescription: 'Aufgabendefinition ausklappen',
            collapseDescription: 'Aufgabendefinition einklappen',
            context: 'Aufgabenkontext',
            product: 'Produkt',
            taskName: 'Aufgabenname'
        },
        taskViews: {
            defaultView: {
                fileAlreadyExists: 'Es wurden bereits Dateien gleichen Namens zur Liste für die Datenbereitstellung hochgeladen. Die folgenden Dateien werden nicht erneut hinzugefügt:<br /><br />{0}<br /><br />Prüfe bitte, ob du dabei bist, die richtigen Dateien hochzuladen.',
                inputs: {
                    headerTitle: 'Material',
                    downloadFiles: 'Alle Dateien herunterladen',
                    table: {
                        tableEmpty: 'Es sind keine bereitgestellten Daten vorhanden',
                        name: 'Name',
                        description: 'Beschreibung',
                        uploadInfo: 'Bereitgestellt/Geändert von',
                        data: 'Bereitgestelltes Material'
                    }
                },
                outputs: {
                    updateTaskEditError: 'Beim Speichern der Daten an \'{0}\' ist folgender Fehler aufgetreten:',
                    updateTaskEditSuccess: {
                        standard: 'Die bereitgestellten Daten wurden erfolgreich an \'{0}\' gespeichert',
                        short: 'Daten gespeichert'
                    },
                    deleteTaskEditError: 'Beim Löschen der Daten an \'{0}\' ist folgender Fehler aufgetreten:',
                    deleteTaskEditSuccess: {
                        standard: 'Die bereitgestellten Daten an \'{0}\' wurden erfolgreich gelöscht',
                        short: 'Daten gelöscht'
                    },
                    updateTaskDecisionError: 'Beim Speichern der Entscheidung ist folgender Fehler aufgetreten:',
                    updateTaskDecisionSuccess: {
                        standard: 'Die Entscheidung wurde erfolgreich gespeichert',
                        short: 'Entscheidung gespeichert'
                    },
                    deleteTaskDecisionError: 'Beim Löschen der Entscheidung ist folgender Fehler aufgetreten:',
                    deleteTaskDecisionSuccess: {
                        standard: 'Die Entscheidung wurde erfolgreich gelöscht',
                        short: 'Entscheidung gelöscht'
                    },
                    headerTitle: 'Bereitstellen',
                    table: {
                        tableEmpty: 'Es sind keine Daten bereitzustellen',
                        noDataProvided: 'Keine Daten bereitgestellt',
                        dataProvided: 'Daten wurden bereitgestellt',
                        name: 'Name',
                        description: 'Beschreibung',
                        uploadInfo: 'Bereitgestellt/Geändert von',
                        data: 'Datenbereitstellung',
                        linkPlaceholder: 'URL hier eingeben',
                        addFiles: 'Weitere Dateien hochladen',
                        changeFile: 'Vorhandene Datei ersetzen',
                        removeData: 'Bereitgestellte Daten löschen',
                        noDecisionSelected: 'Keine Entscheidung getroffen',
                        decisionSelected: 'Entscheidung getroffen',
                        decisionPlaceholder: 'Entscheidung auswählen',
                        resetDecision: 'Entscheidung löschen',
                        defaultChoiceName: 'Triff eine Entscheidung',
                        defaultChoiceDescription: 'Mit deiner Auswahl bestimmst du den weiteren Prozessverlauf',
                        contentTypesSelectLabels: {
                            placeholder: 'Was möchtest du bereitstellen?',
                            backToSelection: 'Zurück zur Auswahl',
                            DOCUMENT: 'Datei hochladen',
                            TEXT: 'Text hinterlegen',
                            HYPERLINK: 'Link hinterlegen'
                        }
                    }
                }
            },
            titleDoc: {
                getTitleDocError: 'Beim Abrufen der Titeldoku ist folgender Fehler aufgetreten:',
                getContractManagerInfo: {
                    standard: 'Für das Produkt mit der Produktnummer \'{0}\' konnten keine Informationen zum Vertragsmanager abgerufen werden. Ursache dafür kann unter anderem mangelnde Datenpflege oder Beendigung des Arbeitsverhältnisses sein.',
                    short: 'Keine Infos zum Vertragsmanager vorhanden'
                },
                getForeignTitleDocError: 'Beim Abrufen der titelfremden Titeldoku ist folgender Fehler aufgetreten:',
                updateTitleDocError: 'Beim Speichern der Titeldokumentation ist folgender Fehler aufgetreten:',
                getForeignTitleDocSuccess: {
                    standard: 'Die Titeldoku für \'{0}\' wurde erfolgreich geladen und gespeichert',
                    short: 'Titeldoku geladen und gespeichert'
                },
                foreignTitleDocNotExisting: {
                    standard: 'Für \'{0}\' konnte keine Titeldoku gefunden werden',
                    short: 'Keine Titeldoku gefunden'
                },
                getForeignTitleDocButtonText: 'Titeldoku suchen',
                getForeignTitleDocPopup: {
                    title: 'Vorhandene Titeldoku suchen und laden',
                    text: 'Mit Eingabe einer Produktnummer lässt sich die Titeldoku des entsprechenden Produkts laden und für das aktuelle Produkt wiederverwenden.<br /><u><b>Wichtig:</b></u> Wird die Titeldoku eines anderen Produkts geladen, überschreibt sie die Formularfelder deines aktuellen Produkts. Überlege bitte gut, ob die vorhandenen Informationen ersetzt werden können! Darüber hinaus sind die übernommenen Werte bitte immer gründlich auf ihre Richtigkeit zu prüfen!',
                    inputPlaceholder: 'Produktnummer eingeben',
                    confirmText: 'Titeldoku-Informationen laden und anwenden'
                }
            },
            briefing: {
                getProductStateError: '@:product.getProductStateError',
                getBriefingError: 'Beim Abrufen des Briefing-Formulars ist folgender Fehler aufgetreten:',
                getReferenceError: 'Beim Abrufen des referenzierten Produkts ist folgender Fehler aufgetreten:',
                updateBriefingError: 'Beim Speichern des Briefing-Formulars ist folgender Fehler aufgetreten:',
                getForeignBriefingError: 'Beim Abrufen des titelfremden Briefing-Formulars ist folgender Fehler aufgetreten:',
                getForeignBriefingSuccess: {
                    standard: 'Das Briefing-Formular für \'{0}\' wurde erfolgreich geladen und gespeichert',
                    short: 'Briefing-Formular geladen und gespeichert'
                },
                foreignBriefingNotExisting: {
                    standard: 'Für \'{0}\' konnte kein Briefing-Formular gefunden werden',
                    short: 'Kein Briefing-Formular gefunden'
                },
                chooseAction: 'Wähle eine Aktion',
                getForeignBriefingOptionLabel: 'Briefing-Formular suchen',
                showProductDetailsOptionLabel: 'Produktdetails einsehen',
                showProductContentsOptionLabel: 'Produktbestandteile einsehen',
                showProductMembersOptionLabel: 'Produktmitglieder einsehen',
                getForeignBriefingPopup: {
                    title: 'Vorhandenes Briefing-Formular suchen und laden',
                    text: 'Mit Eingabe einer Produktnummer lässt sich das Briefing-Formular des entsprechenden Produkts laden und für das aktuelle Produkt wiederverwenden.<br /><u><b>Wichtig:</b></u> Wird das Briefing-Formular eines anderen Produkts geladen, überschreibt es die Formularfelder deines aktuellen Produkts. Überlege bitte gut, ob die vorhandenen Informationen ersetzt werden können! Darüber hinaus sind die übernommenen Werte bitte immer gründlich auf ihre Richtigkeit zu prüfen!',
                    inputPlaceholder: 'Produktnummer eingeben',
                    confirmText: 'Briefing-Formular laden und anwenden'
                }
            },
            illustrators: {
                getIllustratorsFormError: 'Beim Abrufen des Illustrator*innen-Formulars ist folgender Fehler aufgetreten:',
                updateIllustratorsFormError: 'Beim Speichern des Illustrator*innen-Formulars ist folgender Fehler aufgetreten:'
            },
            photographers: {
                getPhotographersFormError: 'Beim Abrufen des Fotograf*innen-Formulars ist folgender Fehler aufgetreten:',
                updatePhotographersFormError: 'Beim Speichern des Fotograf*innen-Formulars ist folgender Fehler aufgetreten:'
            },
            einreichung: {
                updateEinreichungFormError: 'Beim Speichern des Einreichung-Formulars ist folgender Fehler aufgetreten:',
                getProductLookupError: 'Beim Abrufen der Produktsuche ist folgender Fehler aufgetreten:',
                getEinreichungFormError: 'Beim Abrufen des Einreich-Formulars ist folgender Fehler aufgetreten:',
                uploadFileError: 'Beim Hochladen der Datei ist folgender Fehler aufgetreten:',
                uploadFileSuccess: {
                    standard: 'Die Datei wurde erfolgreich hochgeladen',
                    short: 'Datei hochgeladen'
                },
                removeFileError: 'Beim Löschen der Datei ist folgender Fehler aufgetreten:',
                removeFileSuccess: {
                    standard: 'Die Datei wurde erfolgreich gelöscht',
                    short: 'Datei gelöscht'
                },
                spinnerText: 'Daten werden geladen...'
            }
        }
    },
    administration: {
        taskOverview: {
            headline: '@:mainNavigation.administration ➛ @:mainNavigation.adminTasks',
            subNavigation: {
                currentTasks: 'Aktive Aufgaben',
                failedJobs: 'Fehlgeschlagene Jobs'
            },
            activeTasks: {
                getCurrentTasksError: 'Beim Abrufen der aktiven Aufgaben ist folgender Fehler aufgetreten:',
                getTaskInfoError: '@:task.getTaskInfoError',
                table: {
                    filterPlaceholder: 'Schnellfilter aktive Aufgaben',
                    tableEmpty: 'Aktuell sind keine aktiven Aufgaben vorhanden',
                    filterNoResults: 'Alle Aufgaben wurden gefiltert',
                    taskName: 'Aufgabenname',
                    processName: 'Prozessname',
                    productNumber: 'Produktnummer',
                    taskContext: 'Aufgabenkontext',
                    creationDate: 'Erstellt am',
                    assignee: 'Bearbeiter',
                    unClaimTask: 'Zuweisung aufheben'
                },
                expandableContent: {
                    heading: 'Eingehende und ausgehende Datenassoziationen der Aufgabe',
                    dataAssociationTable: {
                        tableEmpty: '@:task.taskViews.defaultView.inputs.table.tableEmpty',
                        name: '@:task.taskViews.defaultView.inputs.table.name',
                        uploadInfo: '@:task.taskViews.defaultView.inputs.table.uploadInfo',
                        data: '@:task.taskViews.defaultView.inputs.table.data',
                        defaultChoiceName: '@:task.taskViews.defaultView.outputs.table.defaultChoiceName'
                    }
                }
            },
            failedJobs: {
                getFailedJobsError: 'Beim Abrufen der fehlgeschlagenen Jobs ist folgender Fehler aufgetreten:',
                retryJobError: 'Beim Versuch den Job \'{0}\' erneut zu starten ist folgender Fehler aufgetreten:',
                retryJobSuccess: {
                    standard: 'Der Job \'{0}\' wurde neu gestartet',
                    short: 'Job wurde neu gestartet'
                },
                table: {
                    filterPlaceholder: 'Schnellfilter fehlerhafte Jobs',
                    tableEmpty: 'Aktuell sind keine fehlerhaften Jobs vorhanden',
                    filterNoResults: 'Alle Jobs wurden gefiltert',
                    taskName: 'Aufgabenname',
                    processName: 'Prozessname',
                    productNumber: 'Produktnummer',
                    taskContext: 'Aufgabenkontext',
                    timeStamp: 'Erstellt am',
                    message: 'Fehlermeldung',
                    notCreated: 'Produkt nicht angelegt',
                    retryJob: 'Job erneut ausführen'
                }
            }
        },
        controlling: {
            headline: '@:mainNavigation.administration ➛ @:mainNavigation.adminControlling',
            subNavigation: {
                processModels: 'Prozessmodelle',
                migrationsOverview: 'Migrationsstatus',
                productCompletion: 'Produkte abschließen',
                conflicts: 'Konflikte',
                operation: 'Betrieb',
                testUser: 'Testnutzer-Anlage'
            },
            processModels: {
                getProcessModelsError: 'Beim Abrufen der Prozessmodelle ist folgender Fehler aufgetreten:',
                createProcessModelError: 'Beim Speichern des neuen Prozessmodells ist folgender Fehler aufgetreten:',
                createProcessModelSuccess: {
                    standard: 'Die neuen Prozessmodelle wurden erfolgreich gespeichert',
                    short: 'Neue Prozessmodelle gespeichert'
                },
                activateProcessModelError: 'Beim Aktivieren des Prozessmodells \'{0}\' ist folgender Fehler aufgetreten:',
                activateProcessModelSuccess: {
                    standard: 'Das Prozessmodell \'{0}\' wurde erfolgreich aktiviert',
                    short: '\'{0}\' aktiviert'
                },
                deleteProcessModelError: 'Beim Löschen des Prozessmodells \'{0}\' ist folgender Fehler aufgetreten:',
                deleteProcessModelSuccess: {
                    standard: 'Das Prozessmodell \'{0}\' wurde erfolgreich gelöscht',
                    short: '\'{0}\' gelöscht'
                },
                deleteSelectedProcessModels: {
                    title: 'Du bist dabei {0} Prozessmodelle zu löschen',
                    text: 'Bitte entferne nur Prozessmodelle, bei denen du dir sicher bist, dass diese nicht mehr benötigt werden. Andernfalls kann es zu schweren Beeinträchtigungen des Prozesses kommen. Bist du dir sicher, dass du die selektierten Prozessmodelle löschen möchtest?',
                    confirmText: 'Ja, löschen'
                },
                unknownProcessModel: 'Unbekanntes Prozessmodell',
                active: 'Aktiv',
                inactive: 'Inaktiv',
                table: {
                    filterPlaceholder: 'Schnellfilter Prozessmodelle',
                    tableEmpty: 'Aktuell sind keine Prozessmodelle vorhanden',
                    filterNoResults: 'Alle Prozessmodelle wurden gefiltert',
                    selectAction: 'Aktion auswählen',
                    deploySelectedProcessModels: 'Ausgewählte Modelle aktivieren',
                    deleteSelectedProcessModels: 'Ausgewählte Modelle löschen',
                    selectAllModels: 'Alle Modelle auswählen',
                    unselectAllModels: 'Auswahl aller Modelle aufheben',
                    status: 'Status',
                    name: 'Prozessbezeichnung',
                    version: 'Version',
                    instanceCount: 'Instanzen',
                    openProcessMigration: 'Prozessinstanzen einsehen und migrieren',
                    updatedBy: 'Geändert von',
                    updatedAt: 'Geändert am',
                    deployedAt: 'Aktiviert am',
                    editProcess: 'Im BPMN-Editor öffnen',
                    deployProcess: 'Prozessmodell aktivieren',
                    deployProcessConfirm: 'Wirklich aktivieren?',
                    removeProcess: 'Prozessmodell löschen'
                },
                expandableContent: {
                    headline: 'Was beinhaltet der Prozess {0}?',
                    noProcessDescription: 'Keine Prozessbeschreibung hinterlegt!'
                },
                dropZoneInfo: 'BPMN-Diagramm hier ablegen'
            },
            processMigration: {
                getProcessInstancesError: 'Beim Abrufen der Prozessinstanzen ist folgender Fehler aufgetreten:',
                getProcessActivitiesError: 'Beim Abrufen der Prozessaktivitäten ist folgender Fehler aufgetreten:',
                processInstanceMigrationError: 'Beim Migrieren der Prozessinstanz mit der ID \'{0}\' ist folgender Fehler aufgetreten:',
                processMigrationError: 'Beim Migrieren der Prozessinstanzen ist folgender Fehler aufgetreten:',
                processMigrationSuccess: {
                    standard: 'Die Prozessmigration wurde erfolgreich abgeschlossen',
                    short: 'Prozessmigration abgeschlossen'
                },
                processMigrationFailed: {
                    standard: 'Die Prozessmigration ist abgeschlossen, aber {0} von {1} Prozessinstanzen konnten nicht migriert werden - Schaue für weitere Informationen in die Migrationsübersicht',
                    short: 'Prozessmigration mit Fehlern abgeschlossen ({0}/{1})'
                },
                showDiagramFailed: 'Das BPMN-Diagramm kann nicht angezeigt werden',
                loadingScreenText: 'Die Migration der Prozesse läuft im Hintergrund und kann eine Weile dauern. Du kannst bis zum Abschluss hier warten oder diese Ansicht schließen und den Status später in der Migrationsübersicht einsehen. Bitte beachte, dass sich die Aktivitäten der Instanzen während der Migration ändern können!',
                startMigrationPopup: {
                    title: '⚡ Prozessmigrationen sind kritische Eingriffe ⚡',
                    text: 'Das Migrieren von Prozessinstanzen kann zu unerwünschten Ergebnissen führen, wenn falsche Parameter in der Konfiguration gesetzt werden. Bitte nutze diese Funktion nur, wenn du dir bei der Eingabe der Daten wirklich sicher bist und diese gründlich überprüft hast! Soll die Migration der Instanz(en) jetzt gestartet werden?',
                    confirmText: 'Ja, ich weiß was ich tue',
                    cancelText: 'Ich schau\' nochmal drüber'
                },
                version: 'Neueste Version:',
                processInstances: 'Prozessinstanzen',
                instanceTable: {
                    filterPlaceholder: 'Schnellfilter Prozessinstanzen',
                    tableEmpty: 'Aktuell sind keine laufenden Prozessinstanzen vorhanden',
                    filterNoResults: 'Alle Prozessinstanzen wurden gefiltert',
                    instanceId: 'Prozessinstanz-ID',
                    version: 'Version',
                    productNumber: 'Produktnummer',
                    taskContext: 'Aufgabenkontext',
                    activities: 'Aktivitäten'
                },
                expandConfiguration: 'Migrations-Konfiguration ausklappen',
                collapseConfiguration: 'Migrations-Konfiguration einklappen',
                migrationConfiguration: 'Migrations-Konfiguration',
                migrateReset: 'Migrations-Konfiguration zurücksetzen',
                migrateProcess: 'Prozessmigration ausführen',
                selectMigrationTypeInfo: 'Lass uns mit der Konfiguration beginnen. Bitte wähle zuerst aus, ob du eine einzelne Prozessinstanz, alle Instanzen einer Prozessversion oder deine Filterauswahl migrieren möchtest.',
                migrationType: 'Migrations-Typ',
                selectMigrationType: 'Migrations-Typ auswählen',
                singleInstanceMigration: 'Migriere eine einzelne Prozessinstanz',
                versionMigration: 'Migriere alle Instanzen einer Prozessversion',
                visibleInstancesMigration: 'Migriere eine Auswahl von Instanzen',
                migrateSingleInstanceVersionInfo: 'In Ordnung, wir migrieren eine einzelne Prozessinstanz. Bitte wähle die ID der zu migrierenden Instanz über das folgende Select aus. Als Hilfestellung dient dir die Liste der Prozessinstanzen, in der du die IDs per Klick in die Zwischenablage kopieren kannst. Anschließend fügst du sie ins Select ein. Wähle danach die Zielversion im Select daneben aus.',
                migrateVersionInstancesVersionInfo: 'Alles klar, wir migrieren alle Instanzen einer bestimmten Version. Bitte wähle nun die Prozessversion aus, für die die Instanzen migriert werden sollen. Anschließend wählst du die Zielversion im Select daneben aus.',
                migrateVisibleInstancesVersionInfo: 'Verstanden, dann stelle bitte sicher, dass deine gewünschten Instanzen nun gefiltert sind, da eine Änderung am Filter nach Auswahl der Zielversion nicht mehr möglich ist. Bitte wähle nun die Prozessversion aus, auf die die gefilterten Instanzen migriert werden sollen.',
                selectProcessInstance: 'Prozessinstanz auswählen',
                selectProcessVersion: 'Prozessversion auswählen',
                migrateInstance: 'Migriere Prozessinstanz...',
                migrateVersion: 'Migriere Prozessversion...',
                defineAdditionalActions: 'Sehr gut! Damit sind die notwendigen Informationen für die Migration vorhanden. Wenn notwendig, kannst du nun Prozessvariablen anpassen und Prozessschritte aufeinander abbilden. Diese werden ebenfalls ins Script aufgenommen. Als Hilfestellung dient dir die Liste der Prozessaktivitäten, in der du die IDs per Klick in die Zwischenablage kopieren kannst. Anschließend fügst du sie ins Textfeld ein. Fahre auf der rechten Seite fort, sobald du fertig bist.',
                definePostScript: 'Wähle hier im linken Feld die zu ändernde Prozessvariabel aus und trage den gewünschten Werten im linken Feld ein.',
                defineActivityMappings: 'Trage hier im linken Feld die Aktivität ein, die du auf die Aktivität im rechten Feld abbilden möchtest.',
                doubleCheckAndFinish: "Prüfe sorgfältig deine Eingaben und klicke abschließend auf die Schaltfläche '@:{'administration.controlling.processMigration.migrateProcess'}'",
                migrationScriptEditableHint: 'Wenn du manuelle Anpassungen am Migrations-Skript vornehmen musst, kannst du den Schreibschutz mit einem Klick auf das Schloss aufheben. Bitte tue dies mit Bedacht und nur, wenn es wirklich notwendig ist! ',
                lockMigrationScript: 'Schreibschutz setzen',
                unlockMigrationScript: 'Schreibschutz aufheben',
                processVersion: '...nach Prozessversion',
                targetVersion: 'Ziel-Prozessversion',
                processVariable: 'Zu ändernde Prozessvariable',
                enterProcessVariable: 'Variable eingeben',
                newValue: 'Neuer Wert',
                enterNewValue: 'Neuen Wert eingeben',
                activityIsMissing: 'Mindestens ein Feld für das Abbilden von Aktivitäten wurde nicht befüllt - Das Ausfüllen beider Felder ist notwendig um korrekte Abbildungen von Aktivitäten zu erstellen',
                fromThisActivity: 'Bilde diese Aktivität...',
                toThisActivity: '...auf dieser Aktivität ab',
                enterActivity: 'Aktivitäts-ID eingeben'
            },
            migrationsOverview: {
                getMigrationsListError: 'Beim Abrufen der Prozessmigrationen ist folgender Fehler aufgetreten:',
                getProcessDefinitionsError: 'Beim Abrufen der Prozessdefinitionen ist folgender Fehler aufgetreten:',
                modalTitle: 'Prozessmigration vom {0}',
                migrationsTable: {
                    filterPlaceholder: 'Schnellfilter Migrationen',
                    tableEmpty: 'Aktuell sind keine Prozessmigrationen vorhanden',
                    filterNoResults: 'Alle Prozessmigrationen wurden gefiltert',
                    migrationStatus: {
                        WAITING: 'In Warteschlange',
                        IN_PROGRESS: 'Wird ausgeführt',
                        COMPLETED: 'Abgeschlossen',
                        COMPLETED_WITH_ERRORS: 'Mit Fehlern abgeschlossen',
                        CONFLICT: 'Einige Instanzen stecken fest'
                    },
                    status: 'Status',
                    processName: 'Prozessname',
                    createdAt: 'Gestartet am',
                    completedAt: 'Abgeschlossen am',
                    version: 'Versionsänderung',
                    instancesCount: 'Instanzen - Verarbeitet/Gesamt'
                },
                accordionTitle: 'Verarbeitete Prozessinstanzen',
                instancesTable: {
                    filterPlaceholder: 'Schnellfilter Prozessinstanzen',
                    tableEmpty: 'In der Migration sind keine Prozessinstanzen vorhanden',
                    filterNoResults: 'Alle Prozessinstanzen wurden gefiltert',
                    status: 'Status',
                    processInstanceId: 'Prozessinstanz-ID',
                    errorMessage: 'Fehlermeldung',
                    conflictMessage: 'Die Migration dieser Instanz konnte nicht abgeschlossen werden!',
                    instanceStatus: {
                        PENDING: 'Ausstehend',
                        SUCCESS: 'Erfolgreich',
                        FAIL: 'Fehlgeschlagen',
                        CONFLICT: 'Instanz steckt fest'
                    }
                }
            },
            productCompletion: {
                getCompletedProductsError: 'Beim Abrufen der manuell abgeschlossenen Produkte ist folgender Fehler aufgetreten:',
                getProductListError: '@:productList.getProductListError',
                productCompleteError: 'Beim Versuch das Produkt/die Produkte manuell abzuschließen ist folgender Fehler aufgetreten:',
                productCompleteSuccess: {
                    standard: 'Der Manuelle Abschluss des ausgewählten Produkts/der ausgewählten Produkte war erfolgreich.',
                    short: 'Manueller Abschluss erfolgreich'
                },
                table: {
                    selectAction: 'Abschließen mit Grund...',
                    selectAllProducts: 'Alle Produkte auswählen',
                    unselectAllProducts: 'Auswahl aller Produkte aufheben',
                    filterPlaceholder: '@:productList.table.filterPlaceholder',
                    tableEmpty: '@:productList.table.tableEmpty',
                    filterNoResults: '@:productList.table.filterNoResults',
                    shortTitle: '@:productList.table.shortTitle',
                    productNumber: '@:productList.table.productNumber',
                    releaseDate: '@:productList.table.releaseDate',
                    completionTime: 'Manuell abgeschlossen am',
                    productState: '@:productList.table.productState',
                    ticketNumber: 'Jira-Ticketnummer',
                    openInJira: 'Ticket in Jira öffnen'
                },
                completeProductsPopup: {
                    title: 'Jira-Ticket für Produktabschluss verknüpfen',
                    text: 'Du bist dabei {0} Produkt(e) mit der Begründung \'{1}\' abzuschließen. Um diesen Vorgang zu starten, gib bitte eine Jira-Ticketnummer an, damit der Abschluss des Produkts/der Produkte und dessen Prozesse im Nachhinein mit einer Anforderung in Verbindung gebracht werden kann.',
                    inputPlaceholder: 'PAU- oder PUBSUP-Ticket-Nummer',
                    confirmText: 'Produkte mit gewählter Begründung abschließen',
                    cancelMessage: {
                        standard: 'Der Vorgang konnte nicht abgeschlossen werden, da keine Ticketnummer angegeben wurde. Der Produktabschluss wurde abgebrochen.',
                        short: 'Abgebrochen, da keine Ticketnummer angegeben'
                    }
                }
            },
            conflicts: {
                getProductConflictsError: 'Beim Abrufen der Produkte mit Konflikten ist folgender Fehler aufgetreten:',
                table: {
                    filterPlaceholder: 'Schnellfilter Konflikte',
                    tableEmpty: 'Aktuell sind keine Produkte mit Konflikten vorhanden',
                    filterNoResults: 'Alle Produkte mit Konflikten wurden gefiltert',
                    productNumber: 'Produktnummer',
                    shortTitle: 'Kurztitel',
                    pspElement: 'PSP-Element',
                    updatedAt: 'Aufgetreten am'
                }
            },
            operation: {
                getOperationsError: 'Beim Abrufen der Betriebs-Operationen ist folgender Fehler aufgetreten:',
                toggleOperationError: 'Beim (De-)Aktivieren der Operation \'{0}\' ist folgender Fehler aufgetreten:',
                activateOperationSuccess: {
                    standard: 'Die Operation \'{0}\' wurde erfolgreich aktiviert',
                    short: 'Operation erfolgreich aktiviert'
                },
                deactivateOperationSuccess: {
                    standard: 'Die Operation \'{0}\' wurde erfolgreich deaktiviert',
                    short: 'Operation erfolgreich deaktiviert'
                },
                operationStates: {
                    active: 'Aktiv',
                    inactive: 'Inaktiv'
                },
                table: {
                    filterPlaceholder: 'Schnellfilter Operationen',
                    tableEmpty: 'Aktuell sind keine Operationen vorhanden',
                    filterNoResults: 'Alle Operationen wurden gefiltert',
                    state: 'Status',
                    configKey: 'Schlüssel/ID',
                    description: 'Beschreibung',
                    startOperation: 'Operation aktivieren',
                    stopOperation: 'Operation stoppen'
                }
            },
            testUser: {
                createTestUserError: 'Bei der Anlage des Testnutzers ist folgender Fehler aufgetreten:'
            }
        },
        playground: {
            headline: 'Spielwiese für SmartForms',
            formHeadline: 'Beispiel eines möglichen Formulars',
            formValid: 'Das Formular ist aktuell valide befüllt und könnte in einer Aufgabe abgeschlossen werden.',
            formInvalid: 'Das Formular beinhaltet Fehler oder es fehlen Informationen. Eine Aufgabe könnte aktuell nicht abgeschlossen werden.',
            uploadJson: 'JSON-Konfiguration hier hochladen',
            invalidJson: {
                standard: 'Die Konfiguration kann nicht übernommen werden, da die JSON-Struktur fehlerhaft ist',
                short: 'Die JSON-Struktur ist fehlerhaft'
            }
        }
    }
}

export default {
    dateTimeFormats,
    messages
}
