import { TinyEmitter } from 'tiny-emitter'

interface EventBus {
    $on(event: string, callback: (...args: unknown[]) => void): void;
    $once(event: string, callback: (...args: unknown[]) => void): void;
    $off(event: string, callback?: (...args: unknown[]) => void): void;
    $emit(event: string, ...args: unknown[]): void;
}

const emitter = new TinyEmitter()

export const eventBus: EventBus = {
    $on: (...args) => emitter.on(...args),
    $once: (...args) => emitter.once(...args),
    $off: (...args) => emitter.off(...args),
    $emit: (...args) => emitter.emit(...args)
}
