import { Client } from '@stomp/stompjs'
import { eventBus } from '@/mixins/eventBus'
import { defineComponent } from 'vue'

const EVENT_BROADCAST_TOPIC = '/cf-events/broadcast'

export default defineComponent({
    name: 'websocket',
    data () {
        return {
            supportedEvents: [
                'task-created',
                'task-claimed',
                'task-completed',
                'task-unassigned'
            ],
            stompClient: null as Client | null
        }
    },
    methods: {
        setupWebSocketConnection (webSocketUrl: string, bearerToken: string): void {
            console.debug('--- Setup WebSocket connection to:' + webSocketUrl)
            if (!this.isConnected()) {
                const stompClient = this.stompClient || new Client({
                    brokerURL: webSocketUrl,
                    connectHeaders: {
                        Authorization: `Bearer ${bearerToken}`
                    }
                })

                stompClient.onConnect = (frame) => {
                    console.debug('--- Websocket connected: ' + frame)

                    this.stompClient = stompClient
                    stompClient.subscribe(EVENT_BROADCAST_TOPIC, (webSocketEvent) => {
                        const event = JSON.parse(webSocketEvent.body)
                        console.debug('--- WebSocket message received:', event)
                        if (this.supportedEvents.includes(event.eventName)) {
                            eventBus.$emit(event.eventName, event)
                        } else {
                            console.warn(`--- Received unknown event: ${event.eventName} ---`)
                        }
                    })
                }

                stompClient.onWebSocketError = (error) => {
                    console.error('Error with websocket', error)
                }

                stompClient.onStompError = (frame) => {
                    console.error('Broker reported error: ' + frame.headers.message)
                    console.error('Additional details: ' + frame.body)
                }

                stompClient.activate()
            }
        },

        closeWebSocketConnection (): void {
            console.debug('--- Closing WebSocket connection! ---')
            if (this.isConnected()) {
                this.stompClient?.deactivate().then(() => {
                    console.debug('--- WebSocket connection closed! ---')
                })
            } else {
                console.debug('--- No open WebSocket connection detected! ---')
            }
        },

        isConnected (): boolean {
            return this.stompClient?.connected || false
        }
    }
}
)
