const dateTimeFormats = {
    short: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    },
    long: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true
    }
}

// A note about vue3 i18n translation texts:
// '{', '}', @ $ | are special characters in i18n now, so they need to be escaped by literal interpolation like in "{0} {'|'} {1} / {2} possible characters"
// For further details see https://vue-i18n.intlify.dev/guide/essentials/syntax#special-characters
const messages = {
    generals: {
        wwaMaintenanceMessage: '<div style="font-family: Source Sans Pro Bold, sans-serif; color: var(--color-warning); margin: 16px 0;">Maintenance work is being carried out! 🪓 🧨</div>Dear users, due to work on WoodWing Assets, CorFlow is also affected and therefore has limited functionality. Please do not use CorFlow until further notice - we will inform you as soon as everything is working smoothly again. (For those users who may have received error messages yesterday and today, the affected tasks will be performed again by CorFlow once WoodWing Assets is available again.)',
        sapMaintenanceMessage: '<div style="font-family: Source Sans Pro Bold, sans-serif; color: var(--color-warning); margin: 16px 0;">Maintenance work on SAP is being carried out! 🧪 🔥 💥</div>Dear users, currently the conversion from SAP to SRE is in progress. Since communication with SAP and its use is not possible within this phase, a few functions in CorFlow are restricted. This involves the creation of new products, as well as the adjustment and confirmation of processes in the SAP schedules. We apologize for any inconvenience and will give the all-clear as soon as the changeover has been completed.<br /><br />Best regards - Team CorFlow',
        sfMaintenanceMessage: '<div style="font-family: Source Sans Pro Bold, sans-serif; color: var(--color-warning); margin: 16px 0;">Maintenance work is being carried out on SiteFusion! 🛠️ 🔬 🩹</div>Dear users, maintenance work will be carried out on SiteFusion from 16:00 to 17:00. Since CorFlow uses some interfaces of SiteFusion, the XML workflow is partially restricted, especially the query of the SiteFusion ID and the package import to SiteFusion. We recommend that you do not continue working on the XML workflow during this period. We ask for your understanding and will give the all-clear as soon as the maintenance work has been completed.<br /><br />Best regards - Team CorFlow',
        infoNotAvailable: 'Information not available',
        valueUnknown: '\'Unknown\'',
        valueEmpty: '\'No value\'',
        unknownError: 'Unknown error - This error message appears if the service is temporarily unavailable, for example during a deployment, or if an error occurred that we\'re not yet aware of. Please turn to Publishing Support at ' +
            '<a href="' + "mailto:publishing-support{'@'}cornelsen.de" + '">' + "publishing-support{'@'}cornelsen.de" + '</a> for help if needed.',
        goToProduct: 'Go to product',
        sapProduct: 'sapProduct',
        save: 'Save',
        cancel: 'Cancel',
        confirmDelete: 'Really delete?',
        systemInformation: 'SYSTEM',
        copyToClipboard: 'Copy to clipboard',
        copiedToClipboard: 'Copied to clipboard',
        planned: 'SOLL',
        actual: 'IST',
        contentTypes: {
            contentWithCorrection: 'Component with correction (e.g. Chapter)',
            contentWithoutCorrection: 'Component without correction (e.g. Cover)'
        },
        stopTaskTermination: {
            info: 'Cancel completion of the task?',
            buttonText: 'Yes, cancel'
        },
        roleLabels: {
            ZP01: 'Editor',
            ZP03: 'External editor',
            ZP04: 'Original author',
            ZP05: 'Publisher',
            ZP06: 'Advisor',
            ZP07: 'Editorial head',
            ZP08: 'Marketing manager',
            ZP10: 'Product manager',
            ZP11: 'Typesetting service provider',
            ZPXX: 'XML service provider',
            EINR: 'Einreichung',
            ZP12: 'Content manager',
            ZP99: 'Project coordinator',
            unknown: 'Unknown role'
        },
        productStateValues: {
            CREATED: 'Created',
            IN_DEVELOPMENT: 'In development',
            FINALIZED: 'Finalized',
            IMPRIMATUR_FINISHED: 'Imprimatur finished',
            ALL_FINISHED: 'Finished',
            FINISHED_MANUALLY: 'Finished manually',
            PRODUCTION_INVALID: 'Production was created by mistake',
            PRODUCTION_CANCELLED: 'Production was canceled (TABG)',
            PRODUCTION_FINISHED: 'Production was successfully completed outside CorFlow',
            PRODUCTION_FINISHED_XML_CANCELLED: 'Production was completed, but XML creation was canceled',
            UNKNOWN: '@:generals.infoNotAvailable'
        },
        booleanValues: {
            true: 'Yes',
            false: 'No'
        },
        materialTypeValues: {
            ZSTP: 'ZSTP - Default product',
            ZSTN: 'ZSTN - Default product, non-material',
            ZUFE: 'ZUFE - Unfinished product',
            ZUFN: 'ZUFN - Unfinished product, non-material',
            ZZSA: 'ZZSA - Magazine',
            ZMPR: 'ZMPR - Series edition',
            ZMPF: 'ZMPF - Series',
            ZWMI: 'ZWMI - Promotional media, material',
            ZWMN: 'ZWMN - Promotional media, non-material',
            ZSON: 'ZSON - Other product',
            UNKNOWN: 'Unknown material type'
        },
        poolProductionValues: {
            no: 'No',
            '50pct': 'Yes (50%)',
            '80pct': 'Yes (80%)',
            UNKNOWN: '–'
        },
        errorCodes: {
            CF_NotFound: 'Nothing could be found for your search or input. You may be accessing something that is not available (anymore). Check your input if necessary and try again. Please turn to Publishing Support at ' +
            '<a href="' + "mailto:publishing-support{'@'}cornelsen.de" + '">' + "publishing-support{'@'}cornelsen.de" + '</a> for help if needed.',
            CF_Forbidden: 'Unfortunately you\'re missing the rights to execute this action',
            CF_Timeout: 'The processing of your request had to be cancelled because it took too long.',
            CF_InputMustBeNumber: 'The input may only consist of digits (0-9)',
            CF_ContentPlanningNotAllowed: 'Content creation is impossible due to rights or current process status',
            CF_ContentCreationAlreadyStarted: 'The evolution process of the product content has been started already',
            CF_ContentNameEmpty: 'The name of the product content must not be empty',
            CF_ContentTypeEmpty: 'The type of the product content must not be empty',
            CF_ContentTypeMaximumViolated: 'You\'ve reached the maximum amoung of product contents of this type',
            CF_TaskAlreadyClaimed: 'Meanwhile, the task has been claimed by a different user',
            CF_TaskDataMissing: 'The provided data isn\'t complete',
            CF_Task_GatewayDecisionMissing: 'It\'s necessary to make a decision, in order to finish the task',
            CF_Task_FormDataInvalid: 'At least one required form field is missing - Please check all the form pages for empty fields, that are marked with a \'*\' sign, and fill them out',
            CF_InvalidBpmnFileExtension: 'The uploaded file is no BPMN file',
            CF_InvalidXMLContent: 'The uploaded file doesn\'t have a valid XML structure',
            CF_MissingProcessDefinition: 'The uploaded BPMN file must at least contain a single process definition',
            CF_SearchParameterAtLeastOne: 'At least one search criterion must be provided',
            CF_SearchTextMustNotBeEmpty: 'A search text must be set',
            CF_ProductIdMustNotBeEmpty: 'A product id must be set',
            CF_SiteFusionProductNotFound: 'There does not exist a product with the given id in SiteFusion',
            CF_UnsupportedProductionLine: 'The production line of your product is not supported in CorFlow(, yet).',
            CF_UnsupportedReprints: 'CorFlow does not support products with reprints(, yet).',
            CF_UnsupportedEditionStatus: 'CorFlow does not support the displayed reprint status(, yet).',
            CF_MissingProcessStep: 'The process steps created in SAP seem to be incorrect! For this product the process step \'License imprimatur\' is required, which is not available.',
            CF_UnsupportedMaterialType: 'The material type of this product is either not set in SAP or is not (yet) supported by CorFlow.',
            CF_UnsupportedMaterialTypeAndMissingProcessStep: 'The material type of this product is not supported by CorFlow and lacks SAP process steps that are required for this product.',
            CF_WwaUnavailable: 'WoodWing Assets is currently not available.',
            CF_WwaProductPathNotFound: 'The title folder in WoodWing Assets does not exist. Please check if the product has been created in the PU Buch.',
            CF_InvalidWwaLink: 'No valid Woodwing Assets link has been provided.',
            CF_NoValidWwaLinkToAPdf: '@:generals.errorCodes.CF_InvalidWwaLink',
            CF_CouldNotExtractAssetId: '@:generals.errorCodes.CF_InvalidWwaLink',
            CF_CouldNotExtractAuthKey: '@:generals.errorCodes.CF_InvalidWwaLink',
            CF_NoSuchAsset: 'The referenced asset does not exist.',
            CF_SearchHitWithoutAssetId: '@:generals.errorCodes.CF_NoSuchAsset',
            CF_NoPdf: 'Non-PDF asset - will be ignored.',
            CF_NoProductContentPdf: 'No PDF found for this product content.',
            CF_NoSuchDocumentReference: '@:generals.errorCodes.CF_NoSuchAsset',
            CF_NoResults: 'WoodWing Assets link not found - the share link may be expired, or points to an empty folder.',
            CF_AssetInTrashFolder: 'The Woodwing Assets link points to a file in the Trash folder.',
            CF_ExpiredOrUnknownAuthKey: 'The Woodwing Assets link cannot be accessed (anymore): \'{0}\'.',
            CF_ExceptionAccessingWwa: 'An error occurred accessing Woodwing Assets (Operation: \'{0}\' Argument: \'{1}\').',
            CF_FileLimitExceeded: 'The upload could not be completed because the file limit was exceeded.',
            CF_FormNotFound: 'No associated form could be found for this product.'
        }
    },
    notification: {
        defaultMessage: 'Currently there are no messages available',
        headlines: {
            info: 'Short information',
            success: 'Awesome!',
            warning: 'Attention!',
            error: 'Oops, something went wrong!'
        }
    },
    contentHeader: {
        goBackTitleDefault: 'Back'
    },
    button: {
        defaultText: 'Button'
    },
    inlineConfirmButton: {
        defaultConfirmText: 'Are you sure?',
        confirm: 'Yes, proceed',
        cancel: 'No, cancel'
    },
    textInput: {
        defaultPlaceholder: 'Enter text',
        maxLengthCounter: '{0} / {1} possible characters',
        patternDoesNotMatch: 'The input is invalid'
    },
    emailInput: {
        defaultPlaceholder: 'Enter e-mail address',
        noValidEmail: 'The input has no valid e-mail format'
    },
    timeCodeInput: {
        noValidTimeCode: 'The entry is not a valid time'
    },
    readOnlyInput: {
        defaultPlaceholder: 'No value'
    },
    numberInput: {
        defaultPlaceholder: 'Enter number here',
        maximumViolated: 'You\'ve exceeded the permissible value of this field - The maximum is \'{0}\'',
        minimumViolated: 'You\'ve fallen below the permissible value of this field - The minimum is \'{0}\'',
        enableEstimate: 'Set as estimate value',
        disableEstimate: 'Set as fixed value',
        maximumReached: 'The maximum of {0} digits is reached!'
    },
    areaInput: {
        defaultPlaceholder: 'Enter text (Shift + Enter for linebreak)',
        maxLengthCounter: '{0} / {1} possible characters',
        patternDoesNotMatch: '@:textInput.patternDoesNotMatch'
    },
    dateInput: {
        defaultPlaceholder: 'Choose a date'
    },
    checkList: {
        customOptionLabel: 'Other'
    },
    dropInput: {
        defaultPlaceholder: 'Drop file here',
        uploading: 'Upload in progress',
        wrongFileType: 'Due to an unsupported file type, the file \'{0}\' can\'t be uploaded. Supported file types are: {1}',
        fileLimitExceeded: 'The upload limit has been exceeded. Only a maximum of {0} files can be uploaded'
    },
    smartSelect: {
        defaultPlaceholder: 'Choose a value'
    },
    entryList: {
        defaultLabel: 'Unknown list entry',
        defaultText: 'No entries available'
    },
    sortableList: {
        headline: 'Sort the entries by simple drag and drop',
        resetList: 'Reset list',
        submitList: 'Apply sort',
        removeEntry: 'Remove entry'
    },
    dynamicInput: {
        inputTypeLabels: {
            text: 'Enter text',
            link: 'Enter link',
            date: 'Enter date',
            email: 'Enter email address',
            number: 'Enter number',
            upload: 'Upload file'
        },
        placeholder: 'What do you want to provide?',
        backToSelection: 'Back to selection'
    },
    smartForm: {
        defaultHeadline: 'Form',
        tableOfContents: 'Table of contents',
        repeatFormDefault: 'Re-use data',
        addPage: 'Add {0}',
        copyPage: 'Copy {0} and add at the end',
        deletePage: 'Delete {0}',
        next: 'Next',
        back: 'Back',
        submit: 'Send',
        requiredFieldsInfo: 'Fields marked with * are required fields which have to be filled',
        incompleteMessage: {
            standard: 'At least one mandatory field was not filled in or is not valid. The affected fields are marked in red.',
            short: 'At least one mandatory field is empty or incorrect'
        },
        apiError: 'An error occurred while receiving continuing information:',
        deleteEntryError: 'The following error occurred when deleting the entry:',
        startSearch: 'Search',
        searchError: 'An error occurred while executing the search:',
        emptySearchResult: {
            standard: 'Unfortunately, the search result of your request is empty',
            short: 'Search result of request is empty'
        },
        resetData: 'Reset result fields',
        collapseSearchResults: 'Collapse search results',
        expandSearchResults: 'Expand search results',
        tableFilter: 'Filter results list',
        pickEntry: 'Choose the desired entry',
        selectData: 'Use this data',
        noSearchDefined: 'Technical error: The configuration of the search is incomplete and it can\'t be executed. Please turn to Publishing Support at ' +
            '<a href="' + "mailto:publishing-support{'@'}cornelsen.de" + '">' + "publishing-support{'@'}cornelsen.de" + '</a> for help if needed.',
        longRequestInfo: 'The request may take some time. If you don\'t want to wait, you can close this view and check later if the request was completed.',
        continueRequestInfo: 'The last request is still being processed. If you don\'t want to wait, you can close this view and return at a later time.'
    },
    timeSpanInput: {
        missingValue: 'Start or end of the time period was not set.',
        negativeTimeSpan: 'The end of the time period must be after the start.',
        calculatedTimeSpan: 'The time period is {0} hours.'
    },
    dualTextInput: {
        defaultPlaceholder: 'Enter text',
        missingValue: 'The second value has to be set.',
        maxLengthCounter: "{0} {'|'} {1} / {2} possible characters"
    },
    modalContainer: {
        defaultHeaderTitle: 'Modal view'
    },
    pageOverlay: {
        defaultExpandPlaceholder: 'Expand view',
        defaultCollapsePlaceholder: 'Collapse view'
    },
    loadingScreen: {
        defaultText: 'We process your request in the background. This can take some time. Please be patient.',
        cancelText: 'Close view'
    },
    table: {
        addUserFilterError: 'An error occurred while adding filter value \'{0}\' to your user profile:',
        addUserFiltersError: 'An error occurred while adding filter values <br />{0}<br /> to your user profile:',
        addUserFilterSuccess: {
            standard: 'Filter value \'{0}\' has been successfully added to your user profile',
            short: '\'{0}\' added'
        },
        addUserFiltersSuccess: {
            standard: 'Filter values <br />{0}<br /> have been successfully added to your user profile',
            short: '{0} filter values added'
        },
        removeUserFilterError: 'An error occurred while removing filter value \'{0}\' from your user profile:',
        removeUserFilterSuccess: {
            standard: 'Filter value \'{0}\' has been successfully removed from your user profile',
            short: '\'{0}\' removed'
        },
        changeMetadataSetError: 'The following error occurred while saving the metadata set \'{0}\' to your user profile:',
        changeSortingError: 'The following error occurred while saving the sorting to your user profile:',
        exportVisibleRows: 'Cornelsen Schulbuchverlag (😜) - Export visible table rows as Excel',
        openFilterButtonText: 'Open advanced filter',
        closeFilterButtonText: 'Close advanced filter',
        customFilterHelpText: '<div class="c_table-filter-hint-headline">- How does the advanced filter work? -</div>\n' +
            '<div class="c_table-filter-hint-paragraph">\n' +
                'The advanced filter can be customized by yourself and it will be restored once you start a new session!\n' +
            '</div>\n' +
            '<div class="c_table-filter-hint-paragraph">\n' +
                'Just choose the desired value from the provided list or just enter any other value.\n' +
                'Confirm your choice by clicking the check icon or by pressing enter, in order to save the filter.\n' +
            '</div>\n' +
            '<div class="c_table-filter-hint-paragraph m--addition">\n' +
                '<span class="c_table-filter-hint-text-highlighted">Hint</span>: Don\'t wonder about your values being saved in the lowercase version. The filter isn\'t case-sensitive.\n' +
                'By the way, this will likewise prevent you from saving the same value multiple times, but with different larger and lower cases.\n' +
            '</div>\n' +
            '<div class="c_table-filter-hint-paragraph">\n' +
                'In order to delete a filter value, just click on it and remove it by pressing the occurring \'x\' symbol.\n' +
            '</div>\n' +
            '<div class="c_table-filter-hint-paragraph">\n' +
                'Notice how the count in the button to open and close the advanced filter will update every time you save or delete a value.\n' +
                'Anytime, this tells you, if some elements might be hidden, because of an active filter.\n' +
            '</div>\n' +
            '<div class="c_table-filter-hint-headline">- How are filter values combined? -</div>\n' +
            '<div class="c_table-filter-hint-paragraph">\n' +
                'If you have multiple filter values in the same column, then the table value must contain at least one of them.\n' +
                'For example: If I save the filter values \'844\' and \'925\' in the same column, the table cell of this column must contain \'844\' AND/OR \'925\' in order to be shown.\n' +
            '</div>\n' +
            '<div class="c_table-filter-hint-paragraph">\n' +
                'If you have filter values in different columns, then every column must fulfill the condition like explained above.\n' +
                'So, if I save the filter value \'Meyer\' in a different column, the table cell of this column must contain \'Meyer\', while the condition above is still fulfilled.\n' +
                'Only if both conditions are fulfilled, the table row will be shown.\n' +
            '</div>\n' +
            '<div class="c_table-filter-hint-headline">- Is there any further syntax via markups? -</div>\n' +
            '<div class="c_table-filter-hint-paragraph">\n' +
                'There are prefixes to adjust the filter logic:\n' +
            '</div>\n' +
            '<div class="c_table-filter-hint-paragraph">\n' +
                '<span class="c_table-filter-hint-text-highlighted">\'not:\'</span>: If your value starts with this prefix, then it will be negated. You\'ll only see the rows where the corresponding table cell does NOT contain the value.\n' +
            '</div>\n' +
            '<div class="c_table-filter-hint-paragraph">\n' +
            '<span class="c_table-filter-hint-text-highlighted">\'only:\'</span>: If your value starts with this prefix, you will be shown only the rows whose corresponding cell contains the value and where there are no other values beyond that. Especially suitable for cells with potentially multiple values.\n' +
            '</div>\n' +
            '<div class="c_table-filter-hint-paragraph">\n' +
                '<span class="c_table-filter-hint-text-highlighted">\'&lt;:\'</span>: If your value starts with this prefix, you\'ll only see the rows where the corresponding table cell value is SMALLER than the entered value. (Beta)\n' +
            '</div>\n' +
            '<div class="c_table-filter-hint-paragraph">\n' +
                '<span class="c_table-filter-hint-text-highlighted">\'&gt;:\'</span>: If your value starts with this prefix, you\'ll only see the rows where the corresponding table cell value is GREATER than the entered value. (Beta)\n' +
            '</div>\n' +
            '<div class="c_table-filter-hint-headline">- Is there a way to combine filter values? -</div>\n' +
            '<div class="c_table-filter-hint-paragraph">\n' +
                'There is the modifier <span class="c_table-filter-hint-text-highlighted">\':&:\'</span>, which can be used to link rules together.\n' +
                'For example, if your filter value has the structure \'Contract:&:Photo\', only those rows are displayed whose cell value contains both \'Contract\' and \'Photo\'.\n' +
                'In this way, it is possible to override the fact that filter values within a column only act independently of each other, i.e. as an OR operation.\n' +
            '</div>\n' +
            '<div class="c_table-filter-hint-paragraph">\n' +
                'Any number of values can be combined (\'Contract:&:Photo:&:New:&:Important\'). \n' +
            '</div>\n' +
            '<div class="c_table-filter-hint-paragraph">\n' +
                'The modifier works equally well in conjunction with <span class="c_table-filter-hint-text-highlighted">\'not:\'</span>. By \'not:Contract:&:Photo\' only the rows are displayed whose cell value contains neither \'Contract\' nor \'Photo\'. \n' +
            '</div>',
        toggleFullscreen: 'Toggle full screen mode',
        filterConfigPlaceholder: 'Filter {0}',
        defaultFilterPlaceholder: 'Enter filter text here',
        defaultTableEmpty: 'No entries available in the table',
        defaultFilterNoResults: 'All entries have been filtered',
        spinnerText: 'Data is still loading',
        expandRow: 'Expand table row',
        collapseRow: 'Collapse table row',
        sets: {
            default: 'Default view',
            projectCoordinator: 'Project coordinator view',
            contentManager: 'Content manager view',
            serviceProvider: 'Service provider view'
        }
    },
    userHandler: {
        getUsersError: 'An error occurred while retrieving the list of users:',
        getUserSettingsError: 'An error occurred while retrieving your specific user information. We\'ll run the application with default settings. Error info: ',
        updateUserSettingsError: 'An error occurred while updating your specific user information:'
    },
    downloadHandler: {
        downloadError: 'An error occurred while downloading the file: ',
        untitled: 'Untitled'
    },
    taskAssignmentHandler: {
        assignTaskError: 'An error occurred while assigning the task \'{0}\':',
        assignTaskSuccess: {
            standard: 'The task \'{0}\' has been successfully assigned to you',
            short: 'Task assigned to you'
        },
        unAssignTaskError: 'An error occurred while canceling the assignment of task \'{0}\':',
        unAssignTaskSuccess: {
            standard: 'The assignment of task \'{0}\' has been successfully canceled',
            short: 'Assignment of task canceled'
        },
        taskNotAssigned: 'Not assigned - Be fast - Some users are watching this right now',
        taskAssignedTo: 'Assigned to \'{0}\'',
        taskAssignedToMe: 'Assigned to me'
    },
    taskExecutionHandler: {
        saveFormError: 'An error occurred while saving the form \'{0}\':'
    },
    smartFormHelper: {
        formNotExisting: {
            standard: 'No corresponding form could be found for the key \'{0}\'',
            short: 'The form type does not exist'
        }
    },
    generatePdfPreviewHandler: {
        getContentListError: 'An error occurred while retrieving the list of product contents:',
        getPdfListError: 'An error occurred while retrieving the PDF preview files:',
        generatePdfError: 'An error occurred while generating the PDF preview:',
        noPdfsFound: {
            standard: 'No PDFs could be found for the product components',
            short: 'No PDFs found'
        }
    },
    mainNavigation: {
        getUserInfoError: 'An error occurred while retrieving your user information:',
        saveUserPreferencesError: 'An error occurred while saving your user preferences:',
        saveUserPreferencesSuccess: {
            standard: 'Your user-specific notification settings have been successfully saved',
            short: 'Notification settings saved'
        },
        resetUserFilterSuccess: {
            standard: 'Your filter settings have been successfully reset',
            short: 'Filter successfully reset'
        },
        productList: 'Product list',
        productSearchPlaceholder: 'Add new product',
        searchError: 'An error occurred while searching for \'{0}\':',
        productExists: {
            standard: 'A product with the product number \'{0}\' already exists. The best way to find it is to use the filter in the product list.',
            short: 'Product \'{0}\' already exists'
        },
        productPreview: {
            conflictMessage: 'The product contains conflicts, which is why it may not be created in CorFlow. The conflicts are highlighted in the preview and give you more details via mouseover.',
            title: 'Product preview',
            openProductWhenCreated: 'Open product after adding',
            createProduct: 'Create product',
            createProductSuccess: {
                standard: 'The product has been successfully created',
                short: 'Product created'
            },
            createProductError: 'An error occurred while creating the product:'
        },
        lastProducts: 'Last visited products',
        taskList: 'Task list',
        administration: 'Administration',
        adminArea: 'Admin areas',
        adminTasks: 'Task overview',
        adminControlling: 'Controlling',
        playground: 'Playground',
        gallery: 'Gallery',
        help: 'Do you need help?',
        language: 'EN',
        languageTooltip: 'Switch to german language',
        salutation: 'Nice to see you, {0}',
        currentReleaseLink: 'Link to current release notes',
        currentBranches: 'Published branches',
        currentFrontendBranch: 'Frontend Branch',
        currentBackendBranch: 'Backend Branch',
        currentUser: 'Logged in as',
        filterSettings: 'Filter settings',
        productListFilter: 'Product list filter',
        taskListFilter: 'Task list filter',
        processMigrationFilter: 'Process migration filter',
        productCompletionFilter: 'Product completion filter',
        resetFilterToStandard: 'Restore defaults',
        systemSettings: 'System settings',
        setNotificationType: 'Reduced-size system messages',
        setContactMail: 'Contact e-mail address',
        setContactMailHint: 'Here you can enter an alternative email address that can be used to contact you. By default, the email account linked to your Cornelsen account is stored here. The email address stored here will also be used for sending notifications.',
        notificationSettings: 'Notification settings',
        setNotificationProduct: 'Product allocations',
        setNotificationProductHint: 'Receive an email when you are assigned to the role of a product.',
        setNotificationTask: 'New tasks',
        setNotificationTaskHint: 'Receive an email when a new task is waiting for you.',
        setCancelTaskTermination: 'Can cancel task completion',
        setCancelTaskTerminationHint: 'Allows you to cancel the completion of the last task within 10 seconds and return to the task.'
    },
    noAccess: {
        pageTitle: 'No access',
        language: '@:mainNavigation.language',
        languageTooltip: '@:mainNavigation.languageTooltip',
        headerTitle: 'Not authenticated',
        headerSubtitle: 'You are missing the necessary rights for the use of CorFlow',
        howToGetAccess: 'How do I get necessary access rights?',
        checkLogin: "It is possible that you already have all the necessary rights, but are using the wrong account to log in to CorFlow. To exclude this you can click on the following button. It will redirect you to the Microsoft login, where you can log in using the email address provided by Cornelsen (firstname.lastname{'@'}cornelsen.de).",
        toMicrosoftLogin: 'Go to Microsoft Login',
        stillNoAccess: 'That did not remedy the situation?',
        furtherActions: 'If you still do not have access to the application, we can still give you the rights once they have been requested. The procedure is described below.',
        internalEmployee: 'I am an internal employee',
        internalEmployeeHelp: 'As an internal employee, you can easily order access to CorFlow via the IT service portal. After approval by your cost centre manager, you can then log in to CorFlow. Feel free to use the following button to be forwarded directly to the IT service portal.',
        toServicePortal: 'Go to IT service portal',
        externalServiceProvider: 'I am an external service provider',
        externalServiceProviderHelp: 'If you are an external service provider, please consult your direct contact at Cornelsen and ask him or her to request access to CorFlow for you via the IT Service Portal. After approval, you can log in to CorFlow with your Cornelsen email address.',
        conclusion: '<span>We hope that we have been able to help you with this. ' +
            'You can find much more information at any time in the <a href="https://confluence.cornelsen.de/pages/viewpage.action?pageId=107776894" title="https://confluence.cornelsen.de/pages/viewpage.action?pageId=107776894" target="_blank">Knowledge Database</a>. ' +
            'If you have any questions regarding the ordering of services or login data, please contact the <a href="' + "mailto:hilfe{'@'}cornelsen.de" + '" title="' + "hilfe{'@'}cornelsen.de" + '">User Help Desk</a>. ' +
            'Please direct all further questions to <a href="' + "mailto:publishing-support{'@'}cornelsen.de" + '" title="' + "publishing-support{'@'}cornelsen.de" + '">Publishing Support</a> or use the <a href="https://cornelsen.refined.site/portal/3" target="_blank">Publishing Support Portal</a>.<br />' +
            '<br />' +
            'Many thanks and best regards,<br />' +
            'Team CorFlow 😊</span>'
    },
    productList: {
        headline: '@:mainNavigation.productList',
        getProductListError: 'An error occurred while retrieving the list of products:',
        getProductStateError: 'An error occurred while retrieving data of the product status:',
        table: {
            filterPlaceholder: 'Quick filter product list',
            tableEmpty: 'No products available',
            filterNoResults: 'All products have been filtered',
            developmentCompleted: 'Product development completed',
            hasErrors: 'Product contains errors',
            noErrors: 'Everything\'s fine',
            productNumber: 'Product number',
            isbn: 'ISBN',
            shortTitle: 'Short title',
            pspElement: 'PSP element',
            profitCenter: 'Profit center',
            projectCoordinators: 'Project coordinators',
            involved: 'Project coordinators / Editorial staff',
            serviceProviders: 'XML and Typesetting Service providers',
            briefingDate: 'Briefing date',
            dataDelivery: 'Data delivery',
            releaseDate: 'Release date',
            productState: 'Product state',
            openProduct: 'Open product',
            projectCoordinator: 'Project coordinator',
            internalEditor: 'Editorial staff',
            externalEditor: 'Editorial staff (external)',
            productForMe: 'My products',
            productForOthers: 'Products of others'
        }
    },
    product: {
        getProductStateError: '@:productList.getProductStateError',
        getProductInformationError: 'An error occurred while retrieving data of the product:',
        startDevelopment: 'Start development process',
        inDevelopment: 'In development',
        isCompleted: 'Product evolution completed',
        startFinalization: 'Start finalization process',
        inFinalization: 'In finalization',
        startDevelopmentPopup: {
            ZSTP: {
                title: 'Reference to the briefing form',
                text: 'If the editorial staff is already enabled to create product contents and add delivery dates, then make sure they were added before you start the task \'Carry out service provider briefing\'. It\'s important, because the information of all the existing product contents will be added automatically to the briefing document, and you won\'t have to add it by yourself.'
            },
            ZUFE: {
                title: 'Note on product development',
                text: 'XML tasks are not generated for non-sellable materials.'
            },
            ZSTN: {
                title: '@:product.startDevelopmentPopup.ZSTP.title',
                text: '@:product.startDevelopmentPopup.ZSTP.text'
            },
            UNKNOWN: {
                name: 'Incompatible material type',
                title: 'Incompatible material type',
                text: 'This product cannot currently be processed through CorFlow, because it has a material type for which there are no compatible processes.'
            },
            confirmText: 'Start development',
            cancelText: 'Cancel start'
        },
        startFinalizationPopup: {
            title: 'Product development will be locked',
            text: 'As soon as you\'ve started the finalization, you will neither be able to update the existing product contents nor to create new ones. Are you sure, that the product content is complete?',
            confirmText: 'Yes, start it',
            cancelText: 'Cancel start'
        },
        startDevelopmentSuccess: {
            standard: 'The product development process has been successfully started',
            short: 'Development started'
        },
        startFinalizationSuccess: {
            standard: 'The product finalization process has been successfully started',
            short: 'Finalization started'
        },
        startDevelopmentError: 'An error occurred while starting the product development process:',
        startFinalizationError: 'An error occurred while starting the product finalization process:',
        subNavigation: {
            cockpit: 'Cockpit',
            info: 'Product data',
            contents: 'Contents',
            schedule: 'Time schedule',
            assetOrders: 'Asset order',
            processHistory: 'Product history'
        },
        services: {
            getServicesError: 'An error occurred while retrieving the list of services for the product:',
            executeServiceError: 'An error occurred while starting the service:',
            executeServiceSuccess: {
                standard: 'The service \'{0}\' was started successfully!',
                short: 'Service started'
            },
            serviceExecuteSuccess: 'The service \'{0}\' was started successfully!',
            toggleToIndexCardView: 'Switch to index card view',
            toggleToListView: 'Switch to list view',
            serviceIsActive: 'Service is running/active',
            serviceIsInactive: 'Service is inactive',
            executeService: 'Start/execute service',
            hint: 'Info',
            description: 'Description',
            execution: 'Execution',
            serviceNotExecuted: 'Has not yet been started.',
            serviceInExecution: 'Has not yet been completed.',
            serviceCompleted: 'Has already been completed.',
            serviceExecutedXTimes: 'Was executed {0} times.',
            titles: {
                PDF_PREVIEW: 'Generate preview PDF',
                AUTHOR_CONTRACT: 'Commission author and publisher contracts',
                ILLUSTRATOR_CONTRACT: 'Commission graphic designers/ illustrators',
                PHOTOGRAPHER_CONTRACT: 'Commission photographers',
                EINREICHUNG: 'Start submission for ministry approval',
                TEST_PROCESS: 'Test-123'
            },
            descriptions: {
                PDF_PREVIEW: 'Here you can merge a PDF from a selection of the most recently submitted PDF files of all product components.',
                AUTHOR_CONTRACT: 'The necessary information is requested here and sent to contract management. A document is also stored.',
                ILLUSTRATOR_CONTRACT: 'The necessary information is requested here and sent to media management. You order the graphics yourself as before.',
                PHOTOGRAPHER_CONTRACT: 'The necessary information is requested here and sent to media management. You order the photos yourself as before.',
                BRIEFING: 'Prepare the service provider briefing with the editorial team and then carry it out with the typesetting service provider.',
                EINREICHUNG: 'Start submission for ministry approval for a product',
                TEST_PROCESS: 'When in doubt, the guilty party is always Shania Twain...'
            },
            infoMessages: {
                generatePdfInfo: 'Can be executed at any time as soon as PDFs are available',
                commissioningInfo6: 'To be completed up to 6 weeks before the license imprimatur',
                commissioningInfo12: 'To be completed up to 12 weeks before the license imprimatur',
                commissioningOverdue: 'If commissions are still required, these are overdue',
                commissioningImpossible: 'Commissioning no longer possible after "LR Imprimatur" deadline',
                contractOverdue: 'If contracts are still required, commissioning is overdue',
                contractImpossible: 'Contract creation no longer possible after "LR Imprimatur" deadline',
                executePriorToDevelopment: 'Must be carried out before work on the product can begin',
                createXmlData: 'The service provider begins with the conclusion of "Print realization"'
            },
            generatePdfPreview: {
                modalTitle: 'Arrange PDF files for preview',
                pdfConflictWarning: 'At least one product component contains conflicts, which may cause the preview to be incomplete',
                startPdfGeneration: 'Start preview PDF generation',
                generatingPreviewPdf: 'The PDF preview is being generated. This process may take a moment, depending on the size and content of the individual files. Please wait a moment.'
            },
            table: {
                status: 'Status',
                title: 'Service',
                tableEmpty: 'Currently, there are no services available for you'
            }
        },
        tasks: {
            getTasksError: 'An error occurred while retrieving the available tasks for this product:',
            headline: 'Product related tasks',
            table: {
                tableEmpty: 'Currently, there are no tasks available in this product',
                taskName: '@:taskList.table.taskName',
                taskContext: '@:taskList.table.taskContext',
                taskRole: '@:taskList.table.taskRole',
                claimAndEditTask: '@:taskList.table.claimAndEditTask'
            }
        },
        details: {
            getProductInformationError: '@:product.getProductInformationError',
            approvedEinreichungCount: 'Approved: {0} out of {1}',
            noGroupInformationPlaceholder: 'For this product there are no entries for this group yet',
            relationsCount: "Incoming: {0} {'|'} Outgoing: {1}",
            relationTypes: {
                DEMO: 'Demo version',
                DOWNLOAD: 'Download',
                COMPLEMENTARY_MEDIUM: '@:product.details.relationTypes.BEGLEITMED',
                BEGLEITMED: 'Complementary medium',
                APP_ZUSMAT: 'App supplementary material',
                DIGZUSMAT: 'Digital supplementary material',
                IDENTISCH: 'Identical in content',
                IDENT_TW: 'Partly identical in content',
                EB_SCOOK: 'E-Book (CV)',
                DLPDF: 'Download PDF',
                W_MITTEL: 'Advertising material',
                CODE: 'Code',
                U_MANAGER: 'Teaching Manager',
                NUTZ_PROD: 'Usage product',
                EB_Z1: 'E-Book Plus',
                PREDECESSOR_SUCCESSOR: 'Is successor of'
            },
            incomingRelation: 'Incoming product relation',
            outgoingRelation: 'Outgoing product relation',
            titles: {
                links: 'Links',
                baseInformation: 'Product information',
                equipmentData: 'Equipment data',
                internalContributors: 'Internal contributors',
                externalContributors: 'External contributors',
                productApprovals: 'Submission status',
                relationData: 'Product relations'
            },
            fields: {
                measurementUnits: {
                    retailPrice: ' EUR',
                    breite: ' cm',
                    hoehe: ' cm',
                    rueckenStaerke: ' mm',
                    gesamtUmfang: ' Pages',
                    totalPageCount: ' Pages'
                },
                linkToWwaLabel: 'WoodWing Assets',
                linkToWwaValue: 'Open crumb path in WoodWing Assets',
                linkToDashboardLabel: 'Contract dashboard',
                linkToDashboardValue: 'View contracts in the contract dashboard',
                linkToRmsLabel: 'Compliance Check (RMS), available 12 months before release date',
                linkToRmsValue: 'View RMS Compliance Check information',
                linkToAiPortalLabel: 'Cornelsen AI Portal',
                linkToAiPortalValue: 'View the Cornelsen AI Portal',
                isbn: 'ISBN',
                productNumber: 'Product number (SAP)',
                orderNumber: 'Order number',
                shortTitle: 'Short title',
                title: 'Title',
                gradeLevels: 'Grade levels',
                retailPrice: 'Retail price (GER)',
                type: 'Product type',
                productionLine: 'Production line',
                series: 'Series',
                seriesEdition: 'Series edition',
                seriesEditionKey: 'Series key',
                profitCenter: 'Profit center',
                plannedReleaseDate: 'TARGET release date',
                edition: 'PSP element of edition',
                druckerei: 'Print Shop',
                druckAuflage: 'Edition',
                druckAuflagenStatus: 'Edition status',
                breite: 'Format width',
                hoehe: 'Format height',
                rueckenStaerke: 'Book spine thickness',
                gesamtUmfang: 'Total page count (SAP)',
                umfang: 'Size',
                farbigkeit: 'Colourfulness',
                editors: 'Editorial staff',
                leadEditors: 'Editor management',
                marketingManagers: 'Marketing management',
                productManagers: 'Product management',
                contractManagers: 'Contract management',
                authors: 'Team of authors',
                publishers: 'Publishers',
                country: 'Country',
                federalState: 'Federal state',
                schoolType: 'School type',
                subject: 'Subject',
                gradeLevel: 'Grade level',
                schoolSubject: 'School Subject',
                status: 'Status',
                productState: 'Product state',
                lastStatusUpdate: 'Last status update',
                materialType: 'Material type',
                linkToStyleGuide: 'Style Guide',
                poolProduction: 'Pool production?',
                testEditionPlanned: 'Test edition?',
                testEditionDataDeliveryDate: 'Print data delivery for test edition',
                isEinreichungNecessary: 'Submission for ministry approval?',
                dateOfEinreichung: 'Date of data delivery for ministry approval submission edition',
                totalPageCount: 'Total page count (Briefing)',
                planedSubmissionDate: 'TARGET submission date',
                serviceProviderReference: 'Reference in the service provider\'s system',
                seriesWordTemplate: 'Reihen-Wordtemplate (Series Word Template)'
            },
            getProductWwaPathError: 'An error occurred while retrieving wwa path of the product:'
        },
        members: {
            getRolesError: 'An error occurred while retrieving the roles of the product:',
            getUsersError: 'An error occurred while retrieving the list of users:',
            addUserError: 'An error occurred while adding user \'{0}\' to role \'{1}\':',
            addUserSuccess: {
                standard: 'The user \'{0}\' has been successfully added to the members of role \'{1}\'',
                short: '\'{0}\' added'
            },
            removeMemberError: 'An error occurred while removing member \'{0}\' from role \'{1}\':',
            removeMemberSuccess: {
                standard: 'The member \'{0}\' has been successfully removed from role \'{1}\'',
                short: '\'{0}\' removed'
            },
            removeMemberPopup: {
                popupTitle: '\'{0}\' still has work to do',
                popupText: '\'{0}\' can\'t be removed as they still have open tasks in this product in their role as \'{1}\'. Please tell \'{0}\' to either cancel the assignment or finish the open tasks. Alternatively, turn to Publishing Support at ' +
            '<a href="' + "mailto:publishing-support{'@'}cornelsen.de" + '">' + "publishing-support{'@'}cornelsen.de" + '</a> for help if needed.',
                popupConfirmText: 'Ok, got it'
            },
            headline: 'Member management',
            table: {
                role: 'Role',
                members: 'Members',
                tableEmpty: 'There are neither roles nor members defined for this product',
                addMember: 'Add member',
                addMyself: 'Add myself',
                selectMemberPlaceholder: 'Select user to add'
            }
        },
        contents: {
            getContentListError: 'An error occurred while retrieving the list of product contents:',
            getContentTypesError: 'An error occurred while retrieving the list of content types:',
            emptyValueWarning: {
                standard: 'The product content is only saved when all mandatory fields have been filled. Changes entered until then will be lost when leaving the page!',
                short: 'Change is applied as soon as missing mandatory fields are filled in'
            },
            updateContentWarning: {
                standard: 'The product content cannot be saved with empty values',
                short: 'The value must not be empty'
            },
            updateContentError: 'An error occurred while saving product content \'{0}\':',
            updateContentSuccess: {
                standard: 'The product content \'{0}\' has been successfully saved',
                short: '\'{0}\' saved'
            },
            switchContentTypeWarning: {
                standard: "The type of the product content '{0}' can only be accepted after you have filled in the mandatory fields '@:{'product.contents.table.pages'}' and '@:{'product.contents.table.submissionDate'}'.",
                short: 'Please fill in the mandatory fields for changing the type'
            },
            startContentImpossiblePopup: {
                title: 'Process execution not possible',
                text: 'The process for this product component cannot currently be started because not all mandatory fields have been filled in. Please enter the planned number of pages and the expected date of manuscript submission and try again afterwards.',
                cancelText: 'Okay, let\'s do it this way'
            },
            startContentPopup: {
                title: 'Implementation will be started',
                text: 'Starting the implementation of this product component with the service provider will result in the product component not being able to be deleted and the process not being able to be stopped in CorFlow. Are you sure you want to start the implementation of this product component now?',
                confirmText: 'Yes, start',
                cancelText: 'No, not yet'
            },
            startContentError: 'An error occurred while starting the implementation of product content \'{0}\':',
            startContentSuccess: {
                standard: 'Implementation of the product content \'{0}\' has been successfully started',
                short: '\'{0}\' started'
            },
            removeContentError: 'An error occurred while removing product content \'{0}\':',
            removeContentSuccess: {
                standard: 'The product content \'{0}\' has been successfully removed',
                short: '\'{0}\' removed'
            },
            addContent: 'Create product content',
            pageCount: 'Total page count:',
            table: {
                noContentCreation: 'Content creation is impossible due to rights or current process status!',
                filterPlaceholder: 'Quick filter product contents',
                tableEmpty: 'No product contents available',
                filterNoResults: 'All product contents have been filtered',
                newLabel: 'New',
                contentName: 'Title',
                contentType: 'Type',
                pages: 'Planned page count',
                submissionDate: 'Manuscript submission',
                testRound: 'Test round',
                startContent: 'Start implementation',
                contentStarted: 'Content process in execution',
                contentFinished: 'Content process completed',
                removeContent: 'Remove content',
                contentNamePlaceholder: 'Enter title',
                contentTypePlaceholder: 'Select type'
            },
            newContent: {
                label: '@:product.contents.addContent',
                contentNamePlaceholder: '@:product.contents.table.contentNamePlaceholder',
                contentTypePlaceholder: '@:product.contents.table.contentTypePlaceholder',
                addContentSuccess: {
                    standard: '@:product.contents.updateContentSuccess.standard',
                    short: '@:product.contents.updateContentSuccess.short'
                },
                addContentError: '@:product.contents.updateContentError',
                saveAndContinue: 'Save and create another',
                saveAndExit: 'Save and close'
            }
        },
        schedule: {
            getMilestonesError: 'An error occurred while retrieving the list of milestones:',
            getContentListError: '@:product.contents.getContentListError',
            saveMilestoneDeadlineError: 'An error occurred while saving the deadline of milestone \'{0}\':',
            saveMilestoneDeadlineSuccess: {
                standard: 'The deadline of milestone \'{0}\' has been successfully saved',
                short: 'Deadline saved'
            },
            replyMilestoneError: 'An error occurred while reporting the milestone \'{0}\' to SAP:',
            replyMilestoneSuccess: {
                standard: 'The milestone \'{0}\' and possible sub milestones have been successfully reported to SAP',
                short: 'Milestone reported to SAP'
            },
            reportingHiddenKey: 'tabg',
            reportingCompletedKey: 'rückgemeldet',
            milestoneDoneKey: 'erledigt',
            legalLicenceImprimaturPopup: {
                title: "Process '@:{'product.schedule.table.processNames.LEGAL_LICENCE_IMPRIMATUR'}' is reported back",
                text: 'This defines all items on the <a href="https://cornelsen-cdp.iviews.empolisservices.com/document/version/Published/confluence-document-56740180/chapter/149479416/" target="_blank">Checklist Licensing Imprimatur</a> as fulfilled. Do you want to continue with the reporting of the operation, confirming that the items on the checklist have been fulfilled?',
                confirmButtonText: 'Yes, I have read and can confirm.'
            },
            table: {
                filterPlaceholder: 'Quick filter milestones',
                tableEmpty: 'No milestones available',
                filterNoResults: 'All milestones have been filtered',
                type: 'Type',
                name: 'Title',
                replyDeadline: 'Deadline',
                status: 'Status',
                deadlineReachedIn: '{0} days remaining',
                deadlineReached: 'Deadline reached!',
                deadlineExceeded: 'Deadline exceeded by {0} days!',
                deadlineChangePossible: 'Calculated deadline - Can be changed manually',
                deadlineChangedManually: 'Deadline changed manually at {0} by \'{1}\'',
                productContentDataProvided: 'Data provided for realization',
                productContentFinished: 'Product content finished',
                productContentInProgressNoDeadline: 'Product content in the making - No deadline',
                reportingStatusDone: 'Reported in SAP',
                reportingStatusDoneAtBy: 'Reported at {0} by \'{1}\'',
                milestoneDoneAt: 'Milestone done at {0}',
                replyMilestone: 'Report milestone',
                processNames: {
                    LEGAL_LICENCE_IMPRIMATUR: 'Print approval: rights',
                    unknown: 'Unknown process name'
                },
                itemTypes: {
                    SCHEDULE: 'Deadline',
                    CONTENT: 'Product content'
                }
            }
        },
        assetOrders: {
            getOrdersError: 'An error occurred while retrieving the list of asset orders:',
            saveOrderError: 'An error occurred when saving the asset order:',
            saveOrderSuccess: {
                standard: 'Your asset order has been successfully saved. Due to the transmission to the RMS, it may take a moment until the order is completely displayed in the list.',
                short: 'Asset order saved'
            },
            getOrderInfoError: 'An error occurred while retrieving the information about asset order \'{0}\':',
            getFilesOfOrderError: 'An error occurred while retrieving the uploaded files of the order:',
            addFileToOrderError: 'An error occurred while uploading the files',
            addFileToOrderSuccess: {
                standard: 'The files have been successfully uploaded and linked',
                short: 'Files uploaded'
            },
            removeFileFromOrderError: 'An error occurred while deleting the file:',
            removeFileFromOrderSuccess: {
                standard: 'The file has been successfully deleted',
                short: 'File deleted'
            },
            addCommentOnOrderError: 'An error occurred while saving the comment to asset order \'{0}\':',
            addCommentOnOrderSuccess: {
                standard: 'The comment was successfully saved to the asset order \'{0}\'',
                short: 'Comment saved'
            },
            switchNeedToTalkOrderError: 'An error occurred while logging on or off the call requirement for asset order \'{0}\':',
            switchNeedToTalkOrderSuccess: {
                standard: 'The call requirement for asset order \'{0}\' was successfully logged on or off',
                short: 'Status changed for call requirement'
            },
            removeOrderError: 'An error occurred while deleting the asset order \'{0}\':',
            removeOrderSuccess: {
                standard: 'The asset order \'{0}\' was successfully deleted',
                short: 'Asset order deleted'
            },
            productNumberOfOriginalError: 'Your input is incompatible. Please use only a valid ISBN-13 format.',
            identicalProductsError: 'Your input is incompatible. Please use only comma separated product numbers (4-13 digits) for the field, without spaces or line breaks. Unfortunately, incompatible values cannot be transmitted to the RMS, as otherwise they cannot be processed there.',
            repeatOrderButtonLabel: 'Duplicate this asset order',
            orderNotEditable: 'The order cannot be edited!',
            assetTypes: {
                AUDIO: 'Audio',
                PICTURE: 'Picture',
                TEXT: 'Text',
                VIDEO: 'Video',
                UNKNOWN: 'Order is synchronized with the RMS...'
            },
            table: {
                filterPlaceholder: 'Quick filter asset orders',
                tableEmpty: 'Currently, there are no asset orders available in this product',
                filterNoResults: 'All asset orders have been filtered',
                assetType: 'Type',
                motif: 'Motif/Title',
                usage: 'Place of use',
                source: 'By',
                reference: 'Reference',
                licensing: 'CS',
                licensingToolTip: 'Collecting society/Licensing type',
                createdAt: 'Created',
                updatedAt: 'Updated',
                comments: 'Comments',
                attachments: 'Attachments',
                flagged: '⚑',
                state: 'Status',
                lastStatusChange: 'Status updated on: {0}',
                clarificationNecessary: 'Clarification announced',
                noClarificationNecessary: 'Clarification unnecessary',
                setClarification: 'Announce need for clarification',
                unsetClarification: 'Sign off need for clarification',
                copyOrder: 'Duplicate order',
                editOrder: 'Edit order',
                removeOrder: 'Remove order'
            },
            modal: {
                commentHistory: 'Comment history on the order',
                noCommentHistory: 'This is where the action is soon to start!<br />Be the first and write a comment about this order.',
                enterCommentHere: 'Write your comment... (Press shift + enter for linebreak)'
            },
            abortUnsavedChangesPopup: {
                title: 'Cancel the submission of your changes?',
                text: '<p>Would you like to leave the order without submitting your changes to RMS? Then select \'Close order\'.</p><p>If you leave the order but want to send your changed details to the media management, activate the checkbox \'Submit changes\' beforehand.</p>',
                checkboxLabel: 'Submit changes',
                confirmText: 'Close order'
            },
            placeNewOrder: 'Place new order',
            backToOrderList: 'Close and return to the list of orders'
        },
        processHistory: {
            getProcessHistoryError: 'An error occurred while retrieving the list of history entries:',
            deletedProductContent: '<i>Deleted product content</i>',
            historyEntryDataObjectTypes: {
                document: 'File provided',
                hyperlink: 'Link provided',
                text: 'Text entered',
                unknown: 'Unknown type'
            },
            correctionRoundZeroRejection: 'Correction round {0}, without rejection',
            correctionRoundOrRejectionRound: 'Correction round or rejection round',
            correctionRoundWithRejection: 'Correction round {0}, rejection round {1}',
            table: {
                filterPlaceholder: 'Quick filter history entries',
                tableEmpty: 'No history entries available',
                filterNoResults: 'All history entries have been filtered',
                type: 'Type',
                taskName: 'Task name',
                contentName: 'Product content',
                testRound: 'Correction round',
                createdAt: 'Created at',
                userName: 'Created by',
                annotation: 'Annotation',
                downloadFiles: 'Download documents of this entry as zip',
                noDataProvided: 'No further content can be displayed for this task (yet). We are working on it! 🔨⚠️'
            },
            expandableContent: {
                DOCUMENT: 'Provided file',
                CUSTOM_FORM_DOCUMENT: 'Provided file',
                TEXT: 'Written commentary',
                HYPERLINK: 'Provided link',
                UNKNOWN: 'Unknown attachment type',
                conceptDescriptionUpload: 'Concept description for the produc',
                bindingOverallConceptUpload: 'Overall concept for the fulfillment of an educational plan standard',
                previewOfContinuedEditionUpload: 'Preview of the follow-up volume',
                foreignLanguageOverallConceptUpload: 'Overall concept for the series',
                transcriptUpload: 'Transcripts for audios or videos not yet available',
                noticeOfApprovalUpload: 'Notification',
                assessmentUpload: 'Expert feedback',
                stornoUpload: 'Cancellation notice',
                changedPages: 'Changed pages',
                editorialStaffStatement: 'Editorial statement'
            }
        }
    },
    taskList: {
        headline: '@:mainNavigation.taskList',
        tasks: 'Tasks',
        hiddenTasks: 'Hidden tasks',
        getTaskListError: 'An error occurred while retrieving the list of tasks:',
        taskNotProcessable: 'The task cannot be processed!',
        table: {
            filterPlaceholder: 'Quick filter task list',
            filterNoResults: 'All tasks have been filtered',
            taskName: 'Task name',
            productNumber: 'Product number',
            isbn: 'ISBN',
            profitCenter: 'Profit center',
            taskContext: 'Task context',
            briefingDate: 'Briefing date',
            releaseDate: 'Release date',
            creationDate: 'Created at',
            taskRole: 'Assignee / Role',
            tasksForMe: 'Tasks for me',
            tasksForOthers: 'Tasks for others',
            claimAndEditTask: 'Execute task'
        },
        visible: {
            setTaskHiddenError: 'An error occurred when hiding the task:',
            setTaskHiddenSuccess: {
                standard: "The task was successfully hidden and is now located in the tab '@:{'taskList.hiddenTasks'}'",
                short: 'Task hidden'
            },
            table: {
                tableEmpty: 'Currently, there are no tasks available in your products',
                hideTask: 'Hide task'
            },
            backToTotalTaskList: 'Back to total list',
            timeUntilRefresh: 'Check for new tasks in: {0}'
        },
        hidden: {
            setTaskVisibleError: 'An error occurred when displaying the task:',
            setTaskVisibleSuccess: {
                standard: "The task has been successfully inserted and is now located in the tab '@:{'taskList.tasks'}'",
                short: 'Task displayed'
            },
            table: {
                tableEmpty: 'Currently there are no hidden tasks available',
                showTask: 'Display task'
            }
        }
    },
    task: {
        getTaskInfoError: 'An error occurred while retrieving the task information:',
        completeTaskError: 'An error occurred while finishing the task:',
        completeTaskSuccess: {
            standard: 'The task has been successfully finished',
            short: 'Task finished'
        },
        finishTask: 'Finish task',
        returnTask: 'Return task',
        originTitles: {
            TaskList: 'Back to Task list',
            ProductList: 'Back to Product list',
            ProductCockpit: 'Back to Cockpit'
        },
        taskDescription: {
            expandDescription: 'Expand task definition',
            collapseDescription: 'Collapse task definition',
            context: 'Task context',
            product: 'Product',
            taskName: 'Task name'
        },
        taskViews: {
            defaultView: {
                fileAlreadyExists: 'Files with the same name have already been uploaded to the data provision list. The following files will not be added again:<br /><br />{0}<br /><br />Please check that you are uploading the correct files.',
                inputs: {
                    headerTitle: 'Material',
                    downloadFiles: 'Download all files',
                    table: {
                        tableEmpty: 'There is no provided data available',
                        name: 'Name',
                        description: 'Description',
                        uploadInfo: 'Provided/Updated by',
                        data: 'Provided material'
                    }
                },
                outputs: {
                    updateTaskEditError: 'An error occurred while saving data to \'{0}\':',
                    updateTaskEditSuccess: {
                        standard: 'The provided data has been successfully saved to \'{0}\'',
                        short: 'Data saved'
                    },
                    deleteTaskEditError: 'An error occurred while removing data from \'{0}\':',
                    deleteTaskEditSuccess: {
                        standard: 'The provided data has been successfully removed from \'{0}\'',
                        short: 'Data removed'
                    },
                    updateTaskDecisionError: 'An error occurred while saving the decision:',
                    updateTaskDecisionSuccess: {
                        standard: 'The decision has been successfully saved',
                        short: 'Decision saved'
                    },
                    deleteTaskDecisionError: 'An error occurred while removing the decision:',
                    deleteTaskDecisionSuccess: {
                        standard: 'The decision has been successfully removed',
                        short: 'Decision removed'
                    },
                    headerTitle: 'Provide',
                    table: {
                        tableEmpty: 'There is no data to be provided',
                        noDataProvided: 'No data provided',
                        dataProvided: 'Data provided',
                        name: 'Name',
                        description: 'Description',
                        uploadInfo: 'Provided/Updated by',
                        data: 'Data provisioning',
                        linkPlaceholder: 'Enter url here',
                        addFiles: 'Upload additional files',
                        changeFile: 'Replace existing file',
                        removeData: 'Remove provided data',
                        noDecisionSelected: 'No decision made',
                        decisionSelected: 'Decision made',
                        decisionPlaceholder: 'Select a decision',
                        resetDecision: 'Remove decision',
                        defaultChoiceName: 'Make a decision',
                        defaultChoiceDescription: 'With your selection you determine the further course of the process',
                        contentTypesSelectLabels: {
                            placeholder: 'What do you want to provide?',
                            backToSelection: 'Back to selection',
                            DOCUMENT: 'Upload file',
                            TEXT: 'Enter text',
                            HYPERLINK: 'Enter link'
                        }
                    }
                }
            },
            titleDoc: {
                getTitleDocError: 'An error occurred while retrieving the title doc:',
                getContractManagerInfo: {
                    standard: 'No contract manager information could be retrieved for the product with product number \'{0}\'. The reason for this may be, among other things, lack of data maintenance or termination of the employment relationship.',
                    short: 'No info available on contract manager'
                },
                getForeignTitleDocError: 'An error occurred while retrieving a foreign title doc:',
                updateTitleDocError: 'An error occurred while saving the title doc:',
                getForeignTitleDocSuccess: {
                    standard: 'The title doc for \'{0}\' was loaded and saved successfully',
                    short: 'Title doc loaded and saved'
                },
                foreignTitleDocNotExisting: {
                    standard: 'No title document could be found for \'{0}\'',
                    short: 'No title doc found'
                },
                getForeignTitleDocButtonText: 'Search title doc',
                getForeignTitleDocPopup: {
                    title: 'Search and load existing title doc',
                    text: 'By entering a product number, you can load the title documentation of the corresponding product and reuse it for the current product.<br /><u><b>Important:</b></u> If the title documentation of another product is loaded, it will overwrite the existing form fields of your current product. Please consider carefully whether the existing information can be replaced! Furthermore, please always check the adopted values thoroughly for correctness!',
                    inputPlaceholder: 'Enter product number',
                    confirmText: 'Load and apply title doc information'
                }
            },
            briefing: {
                getProductStateError: '@:product.getProductStateError',
                getBriefingError: 'An error occurred while retrieving the briefing form:',
                getReferenceError: 'An error occurred when retrieving the referenced product:',
                updateBriefingError: 'An error occurred while saving the briefing form:',
                getForeignBriefingError: 'An error occurred while retrieving a foreign briefing form:',
                getForeignBriefingSuccess: {
                    standard: 'The briefing form for \'{0}\' was loaded and saved successfully',
                    short: 'Briefing form loaded and saved'
                },
                foreignBriefingNotExisting: {
                    standard: 'No briefing form could be found for \'{0}\'',
                    short: 'No briefing form found'
                },
                chooseAction: 'Choose an action',
                getForeignBriefingOptionLabel: 'Search briefing form',
                showProductDetailsOptionLabel: 'View product details',
                showProductContentsOptionLabel: 'View product contents',
                showProductMembersOptionLabel: 'View product members',
                getForeignBriefingPopup: {
                    title: 'Search and load existing briefing form',
                    text: 'By entering a product number, you can load the briefing form of the corresponding product and reuse it for the current product.<br /><u><b>Important:</b></u> If the briefing form of another product is loaded, it will overwrite the existing form fields of your current product. Please consider carefully whether the existing information can be replaced! Furthermore, please always check the adopted values thoroughly for correctness!',
                    inputPlaceholder: 'Enter product number',
                    confirmText: 'Load and apply briefing form'
                }
            },
            illustrators: {
                getIllustratorsFormError: 'An error occurred while retrieving the illustrators form:',
                updateIllustratorsFormError: 'An error occurred while saving the illustrators form:'
            },
            photographers: {
                getPhotographersFormError: 'An error occurred while retrieving the photographers form:',
                updatePhotographersFormError: 'An error occurred while saving the photographers form:'
            },
            einreichung: {
                updateEinreichungFormError: 'An error occurred while saving the Submission form:',
                getProductLookupError: 'An error occurred when retrieving the referenced product:',
                getEinreichungFormError: 'An error occurred while retrieving the Submission form:',
                uploadFileError: 'An error occurred while uploading the file:',
                uploadFileSuccess: {
                    standard: 'The file has been successfully uploaded',
                    short: 'File uploaded'
                },
                removeFileError: 'An error occurred while deleting the file:',
                removeFileSuccess: {
                    standard: 'The file has been successfully deleted',
                    short: 'File deleted'
                },
                spinnerText: 'Data is being loaded...'
            }
        }
    },
    administration: {
        taskOverview: {
            headline: '@:mainNavigation.administration ➛ @:mainNavigation.adminTasks',
            subNavigation: {
                currentTasks: 'Active tasks',
                failedJobs: 'Failed jobs'
            },
            activeTasks: {
                getCurrentTasksError: 'An error occurred while retrieving the list of current tasks:',
                getTaskInfoError: '@:task.getTaskInfoError',
                table: {
                    filterPlaceholder: 'Quick filter active tasks',
                    tableEmpty: 'Currently, there are no active tasks available',
                    filterNoResults: 'All tasks have been filtered',
                    taskName: 'Task name',
                    processName: 'Process name',
                    productNumber: 'Product number',
                    taskContext: 'Task context',
                    creationDate: 'Created at',
                    assignee: 'Assignee',
                    unClaimTask: 'Cancel assignment'
                },
                expandableContent: {
                    heading: 'Incoming and outgoing data associations of the task',
                    dataAssociationTable: {
                        tableEmpty: '@:task.taskViews.defaultView.inputs.table.tableEmpty',
                        name: '@:task.taskViews.defaultView.inputs.table.name',
                        uploadInfo: '@:task.taskViews.defaultView.inputs.table.uploadInfo',
                        data: '@:task.taskViews.defaultView.inputs.table.data',
                        defaultChoiceName: '@:task.taskViews.defaultView.outputs.table.defaultChoiceName'
                    }
                }
            },
            failedJobs: {
                getFailedJobsError: 'An error occurred while retrieving the list of failed jobs:',
                retryJobError: 'An error occurred while trying to restart the job \'{0}\':',
                retryJobSuccess: {
                    standard: 'The job \'{0}\' has been successfully restarted',
                    short: 'Job has been restarted'
                },
                table: {
                    filterPlaceholder: 'Quick filter job list',
                    tableEmpty: 'Currently, there are no failed jobs available',
                    filterNoResults: 'All jobs have been filtered',
                    taskName: 'Task name',
                    processName: 'Process name',
                    productNumber: 'Product number',
                    taskContext: 'Task context',
                    timeStamp: 'Created at',
                    message: 'Error message',
                    notCreated: 'Product not created',
                    retryJob: 'Retry job'
                }
            }
        },
        controlling: {
            headline: '@:mainNavigation.administration ➛ @:mainNavigation.adminControlling',
            subNavigation: {
                processModels: 'Process models',
                migrationsOverview: 'Migration status',
                productCompletion: 'Product completion',
                conflicts: 'Conflicts',
                operation: 'Operation',
                testUser: 'Test user creation'
            },
            processModels: {
                getProcessModelsError: 'An error occurred while retrieving the list of process models:',
                createProcessModelError: 'An error occurred while saving the new process models:',
                createProcessModelSuccess: {
                    standard: 'The new process models have been successfully saved',
                    short: 'New process models saved'
                },
                activateProcessModelError: 'An error occurred while activating process model \'{0}\':',
                activateProcessModelSuccess: {
                    standard: 'The process model \'{0}\' has been successfully activated',
                    short: '\'{0}\' activated'
                },
                deleteProcessModelError: 'An error occurred while removing process model \'{0}\':',
                deleteProcessModelSuccess: {
                    standard: 'The process model \'{0}\' has been successfully removed',
                    short: '\'{0}\' removed'
                },
                deleteSelectedProcessModels: {
                    title: 'You are about to delete {0} process models',
                    text: 'Please remove only process models that you are sure are no longer needed. Otherwise, the process may be severely affected. Are you sure you want to delete the selected process models?',
                    confirmText: 'Yes, delete'
                },
                unknownProcessModel: 'Unknown process model',
                active: 'Active',
                inactive: 'Inactive',
                table: {
                    filterPlaceholder: 'Quick filter process models',
                    tableEmpty: 'Currently, there are no process models available',
                    filterNoResults: 'All process models have been filtered',
                    selectAction: 'Select action',
                    deploySelectedProcessModels: 'Activate selected models',
                    deleteSelectedProcessModels: 'Delete selected models',
                    selectAllModels: 'Select all models',
                    unselectAllModels: 'Unselect models',
                    status: 'Status',
                    name: 'Process designation',
                    version: 'Version',
                    instanceCount: 'Instances',
                    openProcessMigration: 'View and migrate process instances',
                    updatedBy: 'Updated by',
                    updatedAt: 'Updated at',
                    deployedAt: 'Activated at',
                    editProcess: 'Open in BPMN editor',
                    deployProcess: 'Activate process model',
                    deployProcessConfirm: 'Really activate?',
                    removeProcess: 'Remove process model'
                },
                expandableContent: {
                    headline: 'What does the process include {0}?',
                    noProcessDescription: 'No process description deposited!'
                },
                dropZoneInfo: 'Drop BPMN diagram here'
            },
            processMigration: {
                getProcessInstancesError: 'An error occurred while retrieving the list of process instances:',
                getProcessActivitiesError: 'An error occurred while retrieving the list of process activities:',
                processInstanceMigrationError: 'An error occurred while migrating the process instance with ID \'{0}\':',
                processMigrationError: 'An error occurred while migrating the process instances:',
                processMigrationSuccess: {
                    standard: 'The process migration has been executed successfully',
                    short: 'Process migration executed'
                },
                processMigrationFailed: {
                    standard: 'The process migration is finished, but {0} of {1} process instances couldn\'t be migrated - See the migration overview for further information',
                    short: 'Process migration finished with errors ({0}/{1})'
                },
                showDiagramFailed: 'An error occurred while constructing the process diagram',
                loadingScreenText: 'The migration of the processes runs in the background and may take a while. You can wait until it is finished here or close this view and see the status later in the migration overview. Please note that the activities of the instances can change during the migration!',
                startMigrationPopup: {
                    title: '⚡ Process migrations are critical operations ⚡',
                    text: 'Migrating a process instance can lead to unexpected results, if wrong parameters are entered in the configuration. Please, only make use of this function, if you\'re convinced of your inputs by a hundred percent and you double checked them! Shall the migration of the instance(s) be started?',
                    confirmText: 'Yes, I\'m aware of what I\'m doing',
                    cancelText: 'Let me check again'
                },
                version: 'Latest version:',
                processInstances: 'Process instances',
                instanceTable: {
                    filterPlaceholder: 'Quick filter process instances',
                    tableEmpty: 'Currently, there are no running process instances available',
                    filterNoResults: 'All process instances have been filtered',
                    instanceId: 'Process instance ID',
                    version: 'Version',
                    productNumber: 'Product number',
                    taskContext: 'Task context',
                    activities: 'Activities'
                },
                expandConfiguration: 'Expand migration configuration',
                collapseConfiguration: 'Collapse migration configuration',
                migrationConfiguration: 'Migration configuration',
                migrateReset: 'Reset migration configuration',
                migrateProcess: 'Execute process migration',
                selectMigrationTypeInfo: 'Let\'s start with the configuration. First, choose whether you intend to migrate a single process instance, all instances of a specific process version or all currently filtered instances.',
                migrationType: 'Migration type',
                selectMigrationType: 'Select migration type',
                singleInstanceMigration: 'Migrate a single process instance',
                versionMigration: 'Migrate all instances of a process version',
                visibleInstancesMigration: 'Migrate a selection of instances',
                migrateSingleInstanceVersionInfo: 'Alright, we\'re going to migrate a single instance. Please choose the ID of the instance from the select that you intend to migrate. As an assistance you can copy the instance ID to the clipboard via a click from the process instances list. Then you can paste it into the select. Afterwards choose the target version for the migration process in the select next to it.',
                migrateVersionInstancesVersionInfo: 'Fair enough, we\'ll migrate all instances of a specific version. Please choose the process version for which you want the process instances to be migrated. Afterwards choose the target version for the migration process in the select next to it.',
                migrateVisibleInstancesVersionInfo: 'Understood, then please make sure that your desired instances are now filtered, as changing the filter after selecting the target version is no longer possible. Now please select the process version to which you want to migrate the filtered instances.',
                selectProcessInstance: 'Select process instance',
                selectProcessVersion: 'Select process version',
                migrateInstance: 'Migrate process instance...',
                migrateVersion: 'Migrate process version...',
                defineAdditionalActions: 'Very good! With this, all the relevant information for the migration is available. If necessary, you can change process variables or do some activity mappings, now. Those will be added to the script, too. As an assistance you can copy the activity ID to the clipboard via a click from the process activities list. Then you can paste it into the input. Once you\'re done, continue on the right side.',
                definePostScript: 'Choose the process variabe you want to change from the left field and enter the desired value in the right field.',
                defineActivityMappings: 'Enter the activity in the left field that shall be mapped to the activity that\'s entered in the right field.',
                doubleCheckAndFinish: "Double check your inputs and click on the '@:{'administration.controlling.processMigration.migrateProcess'}' button",
                migrationScriptEditableHint: 'If you need to make changes to the migration script manually, you disable the write protection with a click on the lock. Please, act with caution and only, if it\'s really necessary!',
                lockMigrationScript: 'Enable write protection',
                unlockMigrationScript: 'Disable write protection',
                processVersion: '...to process version',
                targetVersion: 'Target process version',
                processVariable: 'Changing process variable',
                enterProcessVariable: 'Enter variable',
                newValue: 'New value',
                enterNewValue: 'Enter new value',
                activityIsMissing: 'At least one field of the activity mapping is empty - It\'s necessary to fill both inputs in order to create correct activity mappings',
                fromThisActivity: 'Map this activity...',
                toThisActivity: '...to this activity',
                enterActivity: 'Enter activity ID'
            },
            migrationsOverview: {
                getMigrationsListError: 'An error occurred while retrieving the list of process migrations:',
                getProcessDefinitionsError: 'An error occurred while retrieving the list of process definitions:',
                modalTitle: 'Process migration of {0}',
                migrationsTable: {
                    filterPlaceholder: 'Quick filter migrations',
                    tableEmpty: 'Currently, there are no process migrations available',
                    filterNoResults: 'All process migrations have been filtered',
                    migrationStatus: {
                        WAITING: 'In queue',
                        IN_PROGRESS: 'In Progress',
                        COMPLETED: 'Completed',
                        COMPLETED_WITH_ERRORS: 'Completed with errors',
                        CONFLICT: 'Some instances are stuck'
                    },
                    status: 'Status',
                    processName: 'Process name',
                    createdAt: 'Created at',
                    completedAt: 'Completed at',
                    version: 'Version change',
                    instancesCount: 'Instances - Processed/Total'
                },
                accordionTitle: 'Processed instances',
                instancesTable: {
                    filterPlaceholder: 'Quick filter process instances',
                    tableEmpty: 'There are no process instances available in this migration',
                    filterNoResults: 'All process instances have been filtered',
                    status: 'Status',
                    processInstanceId: 'Process instance ID',
                    errorMessage: 'Error message',
                    conflictMessage: 'Migration of this instance could not be completed!',
                    instanceStatus: {
                        PENDING: 'Pending',
                        SUCCESS: 'Succeeded',
                        FAIL: 'Failed',
                        CONFLICT: 'Instance is stuck'
                    }
                }
            },
            productCompletion: {
                getCompletedProductsError: 'An error occurred while retrieving the list of manually completed products:',
                getProductListError: '@:productList.getProductListError',
                productCompleteError: 'An error occurred while trying to manually complete the product(s):',
                productCompleteSuccess: {
                    standard: 'Manual completion of the selected product(s) was successful.',
                    short: 'Manual completion successful'
                },
                table: {
                    selectAction: 'Close with reason...',
                    selectAllProducts: 'Select all products',
                    unselectAllProducts: 'Unselect all products',
                    filterPlaceholder: '@:productList.table.filterPlaceholder',
                    tableEmpty: '@:productList.table.tableEmpty',
                    filterNoResults: '@:productList.table.filterNoResults',
                    shortTitle: '@:productList.table.shortTitle',
                    productNumber: '@:productList.table.productNumber',
                    releaseDate: '@:productList.table.releaseDate',
                    completionTime: 'Manually completed at',
                    productState: '@:productList.table.productState',
                    ticketNumber: 'Jira ticket number',
                    openInJira: 'Open ticket in Jira'
                },
                completeProductsPopup: {
                    title: 'Link Jira ticket for product completion',
                    text: 'You are in the process of completing {0} product(s) with reason \'{1}\'. To start this process, please provide a Jira ticket number so that the completion of the product(s) and its processes can be associated with a request after the fact.',
                    inputPlaceholder: 'PAU- or PUBSUP- tickte number',
                    confirmText: 'Complete products with selected reason',
                    cancelMessage: {
                        standard: 'The process could not be completed because no ticket number was specified. The product completion was canceled.',
                        short: 'Cancelled because no ticket number specified'
                    }
                }
            },
            conflicts: {
                getProductConflictsError: 'An error occurred while retrieving the list of products with conflicts:',
                table: {
                    filterPlaceholder: 'Quick filter conflicts',
                    tableEmpty: 'Currently, there are no products with conflicts',
                    filterNoResults: 'All products with conflicts have been filtered',
                    productNumber: 'Product number',
                    shortTitle: 'Short title',
                    pspElement: 'PSP element',
                    updatedAt: 'Occurred at'
                }
            },
            operation: {
                getOperationsError: 'An error occurred while retrieving the list of operations:',
                toggleOperationError: 'An error occurred while (de-)activating the operation \'{0}\':',
                activateOperationSuccess: {
                    standard: 'The operation \'{0}\' was successfully activated',
                    short: 'Operation successfully activated'
                },
                deactivateOperationSuccess: {
                    standard: 'The operation \'{0}\' was successfully deactivated',
                    short: 'Operation successfully deactivated'
                },
                operationStates: {
                    active: 'Active',
                    inactive: 'Inactive'
                },
                table: {
                    filterPlaceholder: 'Quick filter operations',
                    tableEmpty: 'Currently, there are no operations available',
                    filterNoResults: 'All operations have been filtered',
                    state: 'State',
                    configKey: 'Key/ID',
                    description: 'Description',
                    startOperation: 'Activate operation',
                    stopOperation: 'Stop operation'
                }
            },
            testUser: {
                createTestUserError: 'An error occurred while creating the test user:'
            }
        },
        playground: {
            headline: 'Playground for SmartForms',
            formHeadline: 'Example of a possible form',
            formValid: 'The form is currently validly filled and could be completed in a task.',
            formInvalid: 'The form contains errors or is missing information. A task could not be completed at the moment.',
            uploadJson: 'Upload JSON configuration here',
            invalidJson: {
                standard: 'The configuration cannot be accepted because the JSON structure is faulty',
                short: 'The JSON structure is faulty'
            }
        }
    }
}

export default {
    dateTimeFormats,
    messages
}
